import { text } from "@fortawesome/fontawesome-svg-core";

const discounts_data =
  [
    // {
    //   advocisMember: [{
    //     details: [{
    //       controlId: "advocisMember",
    //       formLabel: "Are you a current Advocis member?",
    //       FormControl: [{
    //         type: "radios",
    //         value: 'No',
    //         options: ["No", "Yes"],
    //         dependencies: [
    //           {
    //             condition: "Yes",
    //             controlId: "advocisMemberId",
    //             formLabel: "Enter your Advocis Member ID:",
    //             FormControl: [{
    //               autoFocus: false,
    //               type: "textButtonCombined",
    //               value: "",
    //               placeholder: "",
    //               buttonText: "Check",
    //               buttonControlId: "advocisMemberIdCheck",
    //               buttonOnClick: null,
    //               buttonDisabled: false,
    //             }]
    //           }
    //         ]
    //       }]
    //     }]
    //   }]
    // },
    {
      firstLicensed: [
        {
          details: [
            {
              controlId: "firstLicensed",
              formLabel: "What year were you first licensed for Life and/or Mutual Funds?",
              FormControl: [
                {
                  type: "select",
                  options: ["Choose a Year..."]
                }
              ]
            }
          ]
        }
      ]
    }
  ];
export default discounts_data;
