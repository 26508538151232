import Axios from "axios";
import { parse, j2xParser } from "fast-xml-parser";
import { each, snakeCase, split } from "lodash";
import moment from "moment";
import { calculateDiscount, getPricingSplits } from "../data/pricing";
import _ from "lodash";
import taxRates from "../data/taxRates";
import { pdf } from "@react-pdf/renderer";
import CertificateGeneratorPage from "../../components/CertificateGenerator/CertificateGeneratorPage";

import { insertAttachmentXml } from "./epicXml/insertAttachmentXml";
import { getAttachmentXml } from "./epicXml/getAttachmentXml";
import { uploadAttachmentXml } from "./epicXml/uploadEnvelopeAttachmentXml";
import getUtcTimestamp from "./utc-time";
import { translateString } from "../helpers/translations/translations";

export default class EpicAPI {
  //TODO: investigate effective_date for quote/policy?????
  static getClientAction = "http://webservices.appliedsystems.com/epic/sdk/2018/01/IClient_2018_01/Get_Client";
  static getContactAction = "http://webservices.appliedsystems.com/epic/sdk/2017/02/IContact_2017_02/Get_Contact";
  static getPolicyAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/Policy_2021_01/Get_Policy";
  static getLineAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/ILine_2021_01/Get_Line";
  static getCustomFormAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/ICustomForm_2021_01/Get_CustomForm";
  static getSupplementalScreenAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/ICustomForm_2021_01/Get_CustomForm_SupplementalScreen";
  static getLookupAction = "http://webservices.appliedsystems.com/epic/sdk/2018/01/ILookup/Get_Lookup";
  static getDefaultCreditsDebits =
    "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Get_Transaction_DefaultActionApplyCreditsToDebits";
  static getActivityAction = "http://webservices.appliedsystems.com/epic/sdk/2017/02/IActivity_2017_02/Get_Activity";
  static insertClientAction = "http://webservices.appliedsystems.com/epic/sdk/2018/01/IClient_2018_01/Insert_Client";
  static insertPolicyAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/Policy_2021_01/Insert_Policy";
  static insertTransactionAction = "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Insert_Transaction";
  static insertActivityAction = "http://webservices.appliedsystems.com/epic/sdk/2017/02/IActivity_2017_02/Insert_Activity";
  static updateClientAction = "http://webservices.appliedsystems.com/epic/sdk/2018/01/IClient_2018_01/Update_Client";
  static updateContactAction = "http://webservices.appliedsystems.com/epic/sdk/2017/02/IContact_2017_02/Update_Contact";
  static updatePolicyAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/Policy_2021_01/Update_Policy";
  static updateCustomFormAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/ICustomForm_2021_01/Update_CustomForm";
  static updateActivityAction = "http://webservices.appliedsystems.com/epic/sdk/2017/02/IActivity_2017_02/Update_Activity";
  static updateLineAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/ILine_2021_01/Update_Line";
  static issuePolicyAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/Policy_2021_01/Action_Policy_IssueNotIssuePolicy";
  static actionApplyDefaultCreditsDebits =
    "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Action_Transaction_ApplyCreditsToDebits";
  static actionGetDefaultTaxFee =
    "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Get_Transaction_DefaultActionGenerateTaxFee";
  static actionGenerateTaxFeeAction = "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Action_Transaction_GenerateTaxFee";
  static actionFinanceTransactionAction =
    "http://webservices.appliedsystems.com/epic/sdk/2019/01/ITransaction_2019_01/Action_Transaction_FinanceTransaction";

  static getAttachmentAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/IAttachment_2021_01/Get_Attachment";
  static uploadAttachmentAction = "http://webservices.appliedsystems.com/epic/sdk/2009/11/filetransfer/IAttachmentTransfer/Upload_Attachment_File";
  static insertAttachmentAction = "http://webservices.appliedsystems.com/epic/sdk/2021/01/IAttachment_2021_01/Insert_Attachment";

  //do post request with xml and action - will return json by default, but return raw xml if needed for replacements
  static post = async (requestXML, action, raw = false) => {
    const headers = {
      "Content-Type": "text/xml",
      AuthenticationKey: process.env.REACT_APP_EPIC_KEY,
      DatabaseName: process.env.REACT_APP_EPIC_DB,
      SOAPAction: action,
    };
    if (raw) {
      return Axios.post(process.env.REACT_APP_EPIC_URL, requestXML, {
        headers: headers,
        crossDomain: true,
      })
        .then((response) => {
          // console.log(response.data);
          return response.data;
        })
        .catch(async (e) => await this.writeToLogs(e, action, requestXML, "_post_raw"));
    }

    // const testError = {
    //   response: {
    //     data: 'test error',
    //   },
    // };
    // this.writeToLogs(testError, action, requestXML);

    return Axios.post(process.env.REACT_APP_EPIC_URL, requestXML, {
      headers: headers,
      crossDomain: true,
    })
      .then((response) => response.data)
      .then(async (textResponse) => {
        const parsedResponse = parse(textResponse);

        if (JSON.stringify(parsedResponse).includes("NaN")) {
          // console.log({ parsedResponse });
          await this.writeToLogs(JSON.stringify(parsedResponse), action, requestXML, "_xml_parse_NaN");
        }
        return parsedResponse;
      })
      .catch(async (e) => await this.writeToLogs(e, action, requestXML, "_post_not_raw"));
  };

  static postUpload = async (requestXML, action, raw = false) => {
    const headers = {
      "Content-Type": "text/xml",
      AuthenticationKey: process.env.REACT_APP_EPIC_KEY,
      DatabaseName: process.env.REACT_APP_EPIC_DB,
      SOAPAction: action,
    };
    if (raw) {
      return Axios.post(process.env.REACT_APP_EPIC_ATTACHMENT_URL, requestXML, {
        headers: headers,
        crossDomain: true,
      })
        .then((response) => {
          return response.data;
        })
        .catch(async (e) => {
          // console.log({e})
          await this.writeToLogs(e, action, requestXML, "_postUpload_post_raw");
        });
    }
    return Axios.post(process.env.REACT_APP_EPIC_ATTACHMENT_URL, requestXML, {
      headers: headers,
      crossDomain: true,
    })
      .then((response) => response.data)
      .then((textResponse) => {
        return parse(textResponse);
      })
      .catch(async (e) => {
        //  console.log({e})
        await this.writeToLogs(e, action, requestXML, "_postUpload_post_Not_raw");
      });
  };

  static writeActivityToLogs = async (responseData, action, requestXML, append = "") => {
    const page = window.location.href;
    const params = JSON.stringify({
      page,
      action,
      requestXML,
      response: responseData || "Unknown Response",
      logger: true,
      first: append,
      type: "Activity",
    });
    console.log("attempting to write the current Activity to logs");
    try {
      await Axios.post(`https://${window.location.host}/logs/`, params, {
        headers: { "Content-Type": "application/json" },
        crossDomain: true,
      });
    } catch (error) {
      console.log(error);
    }
    console.log("attempting to write to logs end");
  };

  static writeToLogs = async (e, action, requestXML, append = "") => {
    const page = window.location.href;
    const params = JSON.stringify({
      page,
      action,
      requestXML,
      error: e.response ? e.response.data : e.message || "Unknown Error",
      logger: true,
      first: append,
    });
    // console.log("test log");
    // console.log('https://' + window.location.host + '/logs/', params, {
    //   headers: { 'Content-Type': 'application/json' },
    //   crossDomain: true,
    // });

    console.log("attempting to write to logs");
    try {
      const response = await Axios.post(`https://${window.location.host}/logs/`, params, {
        headers: { "Content-Type": "application/json" },
        crossDomain: true,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    console.log("attempting to write to logs end");
  };

  //convert a xml item to json
  static parseItem = (item, key) => {
    if (typeof key == "string") {
      let _key = split(key, ":");
      if (_key[1]) {
        key = snakeCase(_key[1]);
      } else {
        key = snakeCase(_key[0]);
      }
    }
    if (typeof item == "object") {
      let _item = {};
      each(item, (i, k) => {
        let _i = this.parseItem(i, k);
        _item[_i.key] = _i.item;
      });
      item = _item;
    }
    return { item, key };
  };

  //wrap the request with auth headers and envelope
  static buildRequestXml = (xml) => {
    return `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Header>
              <h:MessageHeader xmlns:h="http://schemas.appliedsystems.com/epic/sdk/2009/07/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                  <h:AuthenticationKey>${process.env.REACT_APP_EPIC_KEY}</h:AuthenticationKey>
                  <h:DatabaseName>${process.env.REACT_APP_EPIC_DB}</h:DatabaseName>
                  <h:Culture i:nil="true"/>
                  <h:IntegrationKey i:nil="true"/>
                  <h:UserCode i:nil="true"/>
              </h:MessageHeader>
          </s:Header>
          <s:Body>
            ${xml}
          </s:Body>
      </s:Envelope>`;
  };

  static insertTransactions = async (quote, client, pricing) => {
    let splits = getPricingSplits(quote, pricing);
    // console.log('Quote, Pricing in insertTransactions',{quote, pricing});
    // console.log('Splits in insertTransactions',{splits});
    let contractID = await this.getContractLookup();
    for (let policy_id of quote.activePolicies) {
      let policy = await this.getPolicy({ policy_id: policy_id });
      // console.log('policy', policy, policy_id);
      // console.log("quote renewal", quote, quote.renewal);
      await this.issuePolicy(policy, quote.renewal);
      let policy_type_code = policy.policy_type_code.substr(0, 2).toLowerCase();
      // console.log('policy_type_code in insertTransactions', policy_type_code);
      let activityCode;
      if (policy_type_code.toLowerCase() === 'ea' || policy_type_code.toLowerCase() === 'a1') {
        activityCode = 'ISSA';
      } else if (policy_type_code.toLowerCase() === 'eb') {
        activityCode = 'ISSB';
      } else if (policy_type_code.toLowerCase() === 'ec') {
        activityCode = 'ISSC';
      } else if (policy_type_code.toLowerCase() === 'ed') {
        activityCode = 'ISSD';
      } else if (policy_type_code.toLowerCase() === 'sp') {
        activityCode = 'ISSP';
      } else if (policy_type_code.toLowerCase() === 'do') {
        activityCode = 'ISSQ';
      }
      if (activityCode) {
        this.insertActivity({
          clientID: client.client_id,
          policyID: policy_id,
          code: activityCode,
          type: "Policy",
        });
      }
      this.closePolicyActivities({
        clientID: client.client_id,
        policyID: policy_id,
      });
      let split = splits[policy_type_code];
      let commission_pct, newb, dfcf, cdis, mdis, ndis, renb, pstx, main_transaction, dfcf_transaction, pstx_transaction;
      let tax,
        taxes = 0;
      let has_discounts = false;
      if (quote && quote.location) {
        tax = taxRates[quote.location];
        _.each(tax, (t) => {
          taxes += t.tax;
        });
      }
      if (split) {
        if (split.zurich) {
          if (split.base) {
            commission_pct = ((split.zurich / split.base) * 100).toFixed(2);
          } else {
            commission_pct = 100;
          }
          if (quote.renewal) {
            renb = await this.insertTransaction(client, policy, "RENB", commission_pct, split.zurich, contractID);
            main_transaction = renb["a:int"];
          } else {
            newb = await this.insertTransaction(client, policy, "NEWB", commission_pct, split.zurich, contractID);
            main_transaction = newb["a:int"];
          }
        }
      }
      let { memberDiscount, couponDFCFDiscount, couponZurichDiscount, licensedZurichPct, licensedDFCFPct } = calculateDiscount(
        quote,
        split.zurich,
        split.dfc
      );
      if (policy_type_code.toLowerCase() !== "sp") {
        // tax on net zurich after discounts
        if (taxes > 0 && main_transaction) {
          let net_zurich = split.zurich - couponZurichDiscount - memberDiscount - licensedZurichPct;
          pstx = await this.insertTaxTransaction(
            main_transaction,
            quote.renewal ? "RENB" : "NEWB",
            net_zurich,
            net_zurich * (taxes / 100),
            quote.location
          );
          pstx_transaction = pstx["a:int"];
        }
        if (quote.coupon && quote.coupon.value && quote.coupon.value > 0) {
          has_discounts = true;
          cdis = await this.insertTransaction(client, policy, "CDIS", commission_pct, -couponZurichDiscount, contractID);
          if (taxes > 0) {
            pstx = await this.insertTaxTransaction(
              cdis["a:int"],
              "CDIS",
              -couponZurichDiscount,
              -couponZurichDiscount * (taxes / 100),
              quote.location
            );
          }
        }
        if (quote.advocisMember === "yes" && quote.memberId !== "") {
          has_discounts = true;
          mdis = await this.insertTransaction(client, policy, "MDIS", commission_pct, -memberDiscount, contractID);
          if (taxes > 0 && mdis) {
            pstx = await this.insertTaxTransaction(mdis["a:int"], "MDIS", -memberDiscount, -memberDiscount * (taxes / 100), quote.location);
          }
        }
        if (quote.firstLicensed) {
          let year_diff = moment().diff(quote.firstLicensed, "years");
          if (year_diff <= 3) {
            has_discounts = true;
            ndis = await this.insertTransaction(client, policy, "NDIS", commission_pct, -licensedZurichPct, contractID);
            if (taxes > 0) {
              pstx = await this.insertTaxTransaction(ndis["a:int"], "NDIS", -licensedZurichPct, -licensedZurichPct * (taxes / 100), quote.location);
            }
          }
        }
      } else {
        // tax on zurich - no discounts on SP
        if (taxes > 0 && main_transaction) {
          pstx = await this.insertTaxTransaction(main_transaction, split.zurich, split.zurich * (taxes / 100), quote.location);
          pstx_transaction = pstx["a:int"];
        }
      }
      if (split) {
        if (split.dfc) {
          dfcf = await this.insertTransaction(client, policy, "DFCF", 100, split.dfc, contractID);
          dfcf_transaction = dfcf["a:int"];
        }
      }
      if (policy_type_code.toLowerCase() !== "sp") {
        if (quote.coupon && quote.coupon.value && quote.coupon.value > 0) {
          await this.insertTransaction(client, policy, "ADIS", commission_pct, -couponDFCFDiscount, contractID);
        }
        /*if (quote.advocisMember === 'yes' && quote.memberId !== '') {
          adis = await this.insertTransaction(client, policy, 'ADIS', commission_pct, -memberDiscount, contractID);
        }*/
        if (quote.firstLicensed) {
          let year_diff = moment().diff(quote.firstLicensed, "years");
          if (year_diff <= 3) {
            await this.insertTransaction(client, policy, "ANDS", commission_pct, -licensedDFCFPct, contractID);
          }
        }
      }
      if (has_discounts) {
        await EpicAPI.getApplyCreditsDebits(client.client_id, policy_id);
      }
      if (quote.timeMethod === "Monthly" && main_transaction) {
        await this.actionFinanceTransaction(client.client_id, main_transaction, quote.renewal ? "RENB" : "NEWB");
      }
      if (quote.timeMethod === "Monthly" && dfcf_transaction) {
        await this.actionFinanceTransaction(client.client_id, dfcf_transaction, "DFCF");
      }
      if (quote.timeMethod === "Monthly" && pstx_transaction) {
        await this.actionFinanceTransaction(client.client_id, pstx_transaction, "PSTX");
      }
      if (quote.renewal) {
        this.insertActivity({
          clientID: client.client_id,
          policyID: policy_id,
          code: "RENP",
          type: "Policy",
        });
      } else {
        // Removed Welcome Activity.
        // this.insertActivity({
        //   clientID: client.client_id,
        //   policyID: policy_id,
        //   code: 'WELC',
        //   type: 'Policy'
        // });
      }
    }
    return true;
  };

  static insertTaxTransaction = async (transaction_id, transaction_type, amount, tax, location) => {
    // console.log('inserting tax transaction for '+transaction_id);

    let govt_id, govt_contact_id, tax_pct;
    if (location) {
      let _tax = taxRates[location];
      govt_id = _tax[0].govt_id;
      govt_contact_id = _tax[0].contact_id;
      tax_pct = _tax[0].tax;
    }

    if (transaction_id) {
      let xml = `<Get_Transaction_DefaultActionGenerateTaxFee xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">
                <TransactionID>${transaction_id}</TransactionID>
        </Get_Transaction_DefaultActionGenerateTaxFee>`;
      let actionXML = await this.post(this.buildRequestXml(xml), this.actionGetDefaultTaxFee, true);
      let match = actionXML.match(/<a:GenerateTaxFee[^>]*>[\s\S]*?<\/a:GenerateTaxFee>/);
      if (match) {
        actionXML = match[0];
      } else {
        return false;
      }
      // console.log(actionXML);
      actionXML = actionXML.replace(
        "<a:GenerateTaxFee>",
        '<GenerateTaxFeeObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
      );
      actionXML = actionXML.replace("</a:GenerateTaxFee>", "</GenerateTaxFeeObject>");
      actionXML = actionXML.replace("<c:Amount>0</c:Amount>", `<c:Amount>${tax}</c:Amount>`);
      actionXML = actionXML.replace('<c:Description i:nil="true"/>', `<c:Description>Tax on Transaction ${transaction_id}</c:Description>`);
      actionXML = actionXML.replace(
        "<c:Description>Tax on &amp;PolDesc&amp; Insurance Premium</c:Description>",
        `<c:Description>Tax on Transaction ${transaction_id}</c:Description>`
      );
      actionXML = actionXML.replace(
        "<c:GovernmentContactID>-1</c:GovernmentContactID>",
        `<c:GovernmentContactID>${govt_contact_id}</c:GovernmentContactID>`
      );
      actionXML = actionXML.replace("<c:GovernmentEntityID>-1</c:GovernmentEntityID>", `<c:GovernmentEntityID>${govt_id}</c:GovernmentEntityID>`);
      actionXML = actionXML.replace("<c:Percentage>0</c:Percentage>", `<c:Percentage>${tax_pct}</c:Percentage>`);
      actionXML = actionXML.replace('<c:StateProvinceCode i:nil="true"/>', `<c:StateProvinceCode>${location}</c:StateProvinceCode>`);
      actionXML = actionXML.replace('<c:TaxFeeCode i:nil="true"/>', `<c:TaxFeeCode>PSTX</c:TaxFeeCode>`);
      actionXML = actionXML.replace("<c:TaxableAmount>0</c:TaxableAmount>", `<c:TaxableAmount>${amount}</c:TaxableAmount>`);
      actionXML = actionXML.replace(
        '<c:TaxableTransactionCode i:nil="true"/>',
        `<c:TaxableTransactionCode>${transaction_type}</c:TaxableTransactionCode>`
      );
      actionXML = actionXML.replace('<c:TypeCode i:nil="true"/>', `<c:TypeCode>%</c:TypeCode>`);
      actionXML = `<Action_Transaction_GenerateTaxFee xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">${actionXML}</Action_Transaction_GenerateTaxFee>`;
      // console.log(this.buildRequestXml(actionXML));
      let response = await this.post(this.buildRequestXml(actionXML), this.actionGenerateTaxFeeAction);
      if (!response || !response["s:Envelope"]) return false;
      if (!response["s:Envelope"]["s:Body"]) return false;
      if (!response["s:Envelope"]["s:Body"]["Action_Transaction_GenerateTaxFeeResponse"]) return false;
      return response["s:Envelope"]["s:Body"]["Action_Transaction_GenerateTaxFeeResponse"]["Action_Transaction_GenerateTaxFeeResult"];
    }
  };

  static insertTransaction = async (client, policy, transaction_code, commission_pct, split, contractID) => {
    split = (+split).toFixed(2);
    //accepts negative + positive values for split, but no 0.00 as it flags an error in the API
    if (Boolean(parseFloat(split))) {
      // console.log('inserting transaction');
      // console.log({client, policy, transaction_code, commission_pct, split, contractID});
      let description = "";
      let hard_commission = commission_pct;
      let prbr_commission = 0;
      switch (transaction_code) {
        case "NEWB":
          description = "New Policy";
          hard_commission = 25;
          prbr_commission = 5;
          break;
        case "RENB":
          description = "Policy Renewal";
          hard_commission = 25;
          prbr_commission = 5;
          break;
        case "CDIS":
          description = "Corporate Partner Discount";
          hard_commission = 25;
          prbr_commission = 5;
          break;
        case "MDIS":
          description = "Advocis Member Discount";
          hard_commission = 25;
          prbr_commission = 5;
          break;
        case "NDIS":
          description = "New Advisor Discount";
          hard_commission = 25;
          prbr_commission = 5;
          break;
        case "DFCF":
          description = "APA DFC Fee";
          break;
        case "ADIS":
          description = "APA Discount";
          hard_commission = 25;
          break;
        case "ANDS":
          description = "APA New Advisor Discount";
          break;
        case "PSTX":
          description = "Tax on E &amp; 0 Coverage";
          break;
        case "LDIS":
          hard_commission = 25;
          prbr_commission = 5;
          break;
        default:
          break;
      }
      // console.log({ effective_date: policy.effective_date });
      let ar_due_date = moment(policy.effective_date).format("YYYY-MM-DD");
      let tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
      let month = moment(policy.effective_date).format("YYYYMM");

      if (policy.description === "Security &amp; Privacy Liability") {
        prbr_commission = 0;
      }
      const commission_type = "@";
      let lineID = await this.getLine(policy.policy_id);
      // console.log({client});
      // console.log({policy});
      // If the client is from Quebec, set branchCode value to 'QUE'
      // let branchCode = client.province === 'QC' ? 'QUE' : 'ABS';

      let xml = `<Insert_Transaction xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">
              <TransactionObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                  <a:AccountID>${client.client_id}</a:AccountID>
                  <a:AccountTypeCode>CUST</a:AccountTypeCode>
                  <a:AttachToOption xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                      <b:OptionName>Policy</b:OptionName>
                      <b:Value>2</b:Value>
                  </a:AttachToOption>
                  <a:Balance>0</a:Balance>
                  <a:BillNumber>0</a:BillNumber>
                  <a:BillNumberOption i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
                  <a:BillingValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/">
                      <b:ARDueDate>${ar_due_date}T00:00:00</b:ARDueDate>
                      <b:AccountingMonth>${month}</b:AccountingMonth>
                      <b:AgencyCode>ABS</b:AgencyCode>
                      <b:BillingModeOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                          <c:OptionName>AgencyBilled</c:OptionName>
                          <c:Value>0</c:Value>
                      </b:BillingModeOption>
                      <b:BranchCode>${policy.branch_code}</b:BranchCode>
                      <b:DepartmentCode>PL</b:DepartmentCode>
                      <b:EffectiveDate>${policy.effective_date}</b:EffectiveDate>
                      <b:GenerateInvoiceDate>${tomorrow}T05:00:00Z</b:GenerateInvoiceDate>
                      <b:ProductionMonth>${month}</b:ProductionMonth>
                      <b:ProfitCenterCode>E&amp;O</b:ProfitCenterCode>
                      <b:Reason i:nil="true"/>
                      <b:ReasonDetails i:nil="true"/>
                      <b:GeneralLedgerAccount i:nil="true"/>
                  </a:BillingValue>
                  <a:Description>${description}</a:Description>
                  <a:DetailValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/"/>
                  <a:InvoiceValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/">
                      <b:SendInvoiceToOption i:nil="true" xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
                      <b:SendInvoiceTos xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_invoice/">
                          <c:SendInvoiceToItem>
                              <c:AccountLookupCode>${client.code}</c:AccountLookupCode>
                              <c:Address xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2009/07/_shared/">
                                  <d:City>${client.city}</d:City>
                                  <d:CountryCode>CAN</d:CountryCode>
                                  <d:County/>
                                  <d:StateOrProvinceCode>${client.province}</d:StateOrProvinceCode>
                                  <d:Street1>${client.address}</d:Street1>
                                  <d:Street2/>
                                  <d:Street3/>
                                  <d:ZipOrPostalCode>${client.postal}</d:ZipOrPostalCode>
                              </c:Address>
                              <c:AddressDescription/>
                              <c:Contact i:nil="true"/>
                              <c:ContactID i:nil="true"/>
                              <c:DeliveryMethod>Mail</c:DeliveryMethod>
                              <c:Email i:nil="true"/>
                              <c:FaxCountryCode i:nil="true"/>
                              <c:FaxExtension i:nil="true"/>
                              <c:FaxNumber i:nil="true"/>
                              <c:GenerateInvoice>true</c:GenerateInvoice>
                              <c:InvoiceMessage i:nil="true"/>
                              <c:InvoiceNumber i:nil="true"/>
                              <c:InvoiceToType>Client</c:InvoiceToType>
                              <c:LoanNumber i:nil="true"/>
                              <c:SiteID i:nil="true"/>
                              <c:SplitReceivableAmount i:nil="true"/>
                              <c:SplitReceivablePercent i:nil="true"/>
                              <c:InvoiceGrouping>Account Page Break</c:InvoiceGrouping>
                              <c:InvoiceGroupingExistingInvoiceNumber i:nil="true"/>
                              <c:BillBrokerNet i:nil="true"/>
                          </c:SendInvoiceToItem>
                      </b:SendInvoiceTos>
                  </a:InvoiceValue>
                  <a:IsReadOnly>false</a:IsReadOnly>
                  <a:ItemNumber i:nil="true"/>
                  <a:PolicyID>${policy.policy_id}</a:PolicyID>
                  <a:PolicyTypeCode i:nil="true"/>
                  <a:TransactionAmount>${split}</a:TransactionAmount>
                  <a:TransactionCode>${transaction_code}</a:TransactionCode>
                  <a:TransactionID>0</a:TransactionID>
                  <a:CommissionsValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/">
                      <b:RevenueDeferralSchedule i:nil="true" xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_commissions/"/>
                      <b:Splits xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_commissions/">
                          <c:SplitItem>
                              <c:AgencyCommissionAmount>0</c:AgencyCommissionAmount>
                              <c:AgencyCommissionPercentage>${hard_commission}</c:AgencyCommissionPercentage>
                              <c:AgencyCommissionTypeCode>%</c:AgencyCommissionTypeCode>
                              <c:AgencySplitAmount>${split}</c:AgencySplitAmount>
                              <c:CompanyPayableDueDate i:nil="true"/>
                              <c:Flag>View</c:Flag>
                              <c:IssuingCompanyLookupCode>ZURIN1</c:IssuingCompanyLookupCode>
                              <c:LineID>${lineID}</c:LineID>
                              <c:MultiCarrierScheduleID>0</c:MultiCarrierScheduleID>
                              <c:PremiumPayableContractID>${contractID}</c:PremiumPayableContractID>
                              <c:PremiumPayableLookupCode>ZURIN1</c:PremiumPayableLookupCode>
                              <c:PremiumPayableTypeCode>CA</c:PremiumPayableTypeCode>
                              <c:ProducerBrokerCommissions xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_commissionsplititem/">
                                  <d:ProducerBrokerCommissionItem>
                                      <d:CommissionAmount>0</d:CommissionAmount>
                                      <d:CommissionID>0</d:CommissionID>
                                      <d:CommissionPercentage>${prbr_commission}</d:CommissionPercentage>
                                      <d:CommissionTypeCode>${commission_type}</d:CommissionTypeCode>
                                      <d:Flag>View</d:Flag>
                                      <d:LookupCode>${process.env.REACT_APP_EPIC_COMMISSION_LOOKUP}</d:LookupCode>
                                      <d:OrderNumber>0</d:OrderNumber>
                                      <d:ProducerBrokerCode>PPay</d:ProducerBrokerCode>
                                      <d:ProductionCredit>100</d:ProductionCredit>
                                      <d:ContractID i:nil="true"/>
                                      <d:PayableDueDate>2021-10-01T00:00:00</d:PayableDueDate>
                                      <d:ShareRevenueAgencyCode i:nil="true"/>
                                      <d:ShareRevenueAgencyID>-1</d:ShareRevenueAgencyID>
                                      <d:ShareRevenueAgencyName i:nil="true"/>
                                      <d:ShareRevenueAmount i:nil="true"/>
                                      <d:ShareRevenueBranchCode i:nil="true"/>
                                      <d:ShareRevenueBranchID>-1</d:ShareRevenueBranchID>
                                      <d:ShareRevenueBranchName i:nil="true"/>
                                      <d:ShareRevenueDepartmentCode i:nil="true"/>
                                      <d:ShareRevenueDepartmentID>-1</d:ShareRevenueDepartmentID>
                                      <d:ShareRevenueDepartmentName i:nil="true"/>
                                      <d:ShareRevenuePercent i:nil="true"/>
                                      <d:ShareRevenueProfitCenterCode i:nil="true"/>
                                      <d:ShareRevenueProfitCenterID>-1</d:ShareRevenueProfitCenterID>
                                      <d:ShareRevenueProfitCenterName i:nil="true"/>
                                      <d:DefaultProducerBrokerCommissionID i:nil="true"/>
                                  </d:ProducerBrokerCommissionItem>
                              </c:ProducerBrokerCommissions>
                          </c:SplitItem>
                      </b:Splits>
                  </a:CommissionsValue>
                  <a:Discrepancy i:nil="true"/>
                  <a:IgnoreAgencyCommissionLessThanProducerBroker>false</a:IgnoreAgencyCommissionLessThanProducerBroker>
                  <a:IgnoreFlatCommission>false</a:IgnoreFlatCommission>
                  <a:BasicInstallmentOption i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
                  <a:InstallmentCycles i:nil="true"/>
                  <a:InstallmentDownPayment i:nil="true"/>
                  <a:InstallmentNumber i:nil="true"/>
                  <a:InstallmentPlanID i:nil="true"/>
                  <a:InstallmentSequenceNumber i:nil="true"/>
                  <a:InstallmentType i:nil="true"/>
                  <a:InstallmentsValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/"/>
                  <a:UpdateInstallmentsOption i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
                  <a:PackagePolicyReplaceFullAnnualizedCommission i:nil="true"/>
                  <a:PackagePolicyReplaceFullAnnualizedPremium i:nil="true"/>
                  <a:InvoicePaymentOption i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
                  <a:PaymentInstallmentItems i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2017/02/_account/_transaction/"/>
                  <a:PaymentLineID i:nil="true"/>
                  <a:PaymentMethodValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/"/>
                  <a:PaymentValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/"/>
                  <a:ServiceID i:nil="true"/>
                  <a:ServiceItemizationID i:nil="true"/>
              </TransactionObject>
          </Insert_Transaction>`;
      // console.log(this.buildRequestXml(xml));
      let response = await this.post(this.buildRequestXml(xml), this.insertTransactionAction);
      if (!response || !response["s:Envelope"]) return false;
      if (!response["s:Envelope"]["s:Body"]) return false;
      if (!response["s:Envelope"]["s:Body"]["Insert_TransactionResponse"]) return false;
      return response["s:Envelope"]["s:Body"]["Insert_TransactionResponse"]["Insert_TransactionResult"];
    } else {
      return false;
    }
  };

  static actionFinanceTransaction = async (client_id, transaction_id, type) => {
    // console.log('financing transaction for client ', client_id, ' and transaction_id ', transaction_id);
    let xml = `<Action_Transaction_FinanceTransaction xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">
            <FinanceTransactionObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AssociatedAccountID>${client_id}</a:AssociatedAccountID>
                <a:TransactionID>${transaction_id}</a:TransactionID>
                <a:TransactionItemsValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_financetransaction/">
                    <b:TransactionItem>
                        <b:FinanceDescription>${type}</b:FinanceDescription>
                        <b:FinanceFlatAmount>0</b:FinanceFlatAmount>
                        <b:FinanceGenerateInvoice>true</b:FinanceGenerateInvoice>
                        <b:FinancePercentage>100</b:FinancePercentage>
                        <b:FinancePercentageFlatAmountOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                            <c:OptionName>Percentage</c:OptionName>
                            <c:Value>0</c:Value>
                        </b:FinancePercentageFlatAmountOption>
                        <b:Flag>Finance</b:Flag>
                        <b:SendInvoiceToAccountLookupCode>FIRSINS-01</b:SendInvoiceToAccountLookupCode>
                        <b:SendInvoiceToAddress xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_shared/">
                            <c:City>Toronto</c:City>
                            <c:CountryCode>CAN</c:CountryCode>
                            <c:County i:nil="true"/>
                            <c:StateOrProvinceCode>ON</c:StateOrProvinceCode>
                            <c:Street1>20 Toronto Street, Suite 700</c:Street1>
                            <c:Street2 i:nil="true"/>
                            <c:Street3 i:nil="true"/>
                            <c:ZipOrPostalCode>M5C 2B8</c:ZipOrPostalCode>
                        </b:SendInvoiceToAddress>
                        <b:SendInvoiceToContact i:nil="true"/>
                        <b:SendInvoiceToContactID>0</b:SendInvoiceToContactID>
                        <b:SendInvoiceToDeliveryMethod>Mail</b:SendInvoiceToDeliveryMethod>
                        <b:SendInvoiceToEmail i:nil="true"/>
                        <b:SendInvoiceToFaxCountryCode i:nil="true"/>
                        <b:SendInvoiceToFaxExtension i:nil="true"/>
                        <b:SendInvoiceToFaxNumber i:nil="true"/>
                        <b:SendInvoiceToLoanNumber i:nil="true"/>
                        <b:TransactionID>${transaction_id}</b:TransactionID>
                    </b:TransactionItem>
                </a:TransactionItemsValue>
            </FinanceTransactionObject>
        </Action_Transaction_FinanceTransaction>`;
    let response = await this.post(this.buildRequestXml(xml), this.actionFinanceTransactionAction);
    // console.log(response);
  };

  static getApplyCreditsDebits = async (client_id, policy_id) => {
    // console.log('getting credits and debits for client '+client_id+' and policy '+policy_id);
    let xml = `<Get_Transaction_DefaultActionApplyCreditsToDebits xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">
            <AssociatedAccountTypeCode>CUST</AssociatedAccountTypeCode>
            <AssociatedAccountID>${client_id}</AssociatedAccountID>
            <AgencyCode>ABS</AgencyCode>
        </Get_Transaction_DefaultActionApplyCreditsToDebits>`;
    let raw_xml = await this.post(this.buildRequestXml(xml), this.getDefaultCreditsDebits, true);
    if (!raw_xml) return false;
    let response = parse(raw_xml);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_Transaction_DefaultActionApplyCreditsToDebitsResponse"]) return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_Transaction_DefaultActionApplyCreditsToDebitsResponse"][
        "Get_Transaction_DefaultActionApplyCreditsToDebitsResult"
      ]
    )
      return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_Transaction_DefaultActionApplyCreditsToDebitsResponse"][
        "Get_Transaction_DefaultActionApplyCreditsToDebitsResult"
      ]["a:ApplyCreditsToDebits"]
    )
      return false;
    let obj =
      response["s:Envelope"]["s:Body"]["Get_Transaction_DefaultActionApplyCreditsToDebitsResponse"][
        "Get_Transaction_DefaultActionApplyCreditsToDebitsResult"
      ]["a:ApplyCreditsToDebits"];
    let _client = {};
    each(obj, (item, key) => {
      let i = this.parseItem(item, key);
      _client[i.key] = i.item;
    });
    let newb, dfcf, pstx;
    let credits = _client["credits"]["credit_item"];
    let debits = _client["debits"]["debit_item"];
    _.each(debits, (d) => {
      if (d["policy_number"] === policy_id && d["transaction_code"] === "DFCF") dfcf = d;
      if (d["policy_number"] === policy_id && d["transaction_code"] === "NEWB") newb = d;
      if (d["policy_number"] === policy_id && d["transaction_code"] === "RENB") newb = d;
      if (d["policy_number"] === policy_id && d["transaction_code"] === "PSTX") pstx = d;
    });
    // console.log({credits, debits, newb, dfcf, pstx});
    if (!!newb && !!dfcf && !!pstx) {
      await this.applyCreditsDebits(client_id, policy_id, credits, debits, newb, dfcf, pstx, raw_xml);
    }
  };

  static applyCreditsDebits = async (client_id, policy_id, credits, debits, newb, dfcf, pstx, raw_xml) => {
    // console.log('applying credits and debits');
    if (_.size(credits) === 0) return;
    let date = moment().format("YYYYMM");
    let credits_xml = "";
    _.each(credits, (credit) => {
      if (credit) {
        let payment =
          '<b:Payments xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/_credititem/"/>';
        var rgxp = new RegExp(
          `<b:CreditItem[^>]*>[\\s\\S]*?<b:PolicyNumber>${policy_id}<\\/b:PolicyNumber>[\\s\\S]*?<b:TransactionCode>${credit.transaction_code}<\\/b:TransactionCode>[\\s\\S]*?<\\/b:CreditItem>`
        );
        let match = raw_xml.match(rgxp);
        if (match) {
          let payment_match = match[0].match(/<b:Payments[^>]*>[\s\S]*?<\/b:Payments>/);
          if (payment_match) {
            payment = payment_match[0];
          }
        }
        let newb_payment = `<b:Payments xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/_credititem/">
                            <c:PaymentItem>
                                <c:ApplyToDebitTransactionID>${newb.transaction_id}</c:ApplyToDebitTransactionID>
                                <c:FullPayment>true</c:FullPayment>
                                <c:PartialPaymentAmount i:nil="true"/>
                            </c:PaymentItem>
                        </b:Payments>`;
        let dfcf_payment = `<b:Payments xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/_credititem/">
                            <c:PaymentItem>
                                <c:ApplyToDebitTransactionID>${dfcf.transaction_id}</c:ApplyToDebitTransactionID>
                                <c:FullPayment>true</c:FullPayment>
                                <c:PartialPaymentAmount i:nil="true"/>
                            </c:PaymentItem>
                        </b:Payments>`;
        let pstx_payment = `<b:Payments xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/_credititem/">
                            <c:PaymentItem>
                                <c:ApplyToDebitTransactionID>${pstx.transaction_id}</c:ApplyToDebitTransactionID>
                                <c:FullPayment>true</c:FullPayment>
                                <c:PartialPaymentAmount i:nil="true"/>
                            </c:PaymentItem>
                        </b:Payments>`;

        switch (credit.transaction_code) {
          case "CDIS":
          case "LDIS":
          case "NDIS":
          case "MDIS":
            if (credit.policy_number === policy_id) payment = newb_payment;
            break;
          case "ADIS":
          case "ALDS":
          case "ANDS":
            if (credit.policy_number === policy_id) payment = dfcf_payment;
            break;
          case "PSTX":
            if (credit.policy_number === policy_id) payment = pstx_payment;
            break;
          default:
            break;
        }

        credits_xml += `<b:CreditItem>
                        <b:ARDueDate>${credit.ar_due_date}</b:ARDueDate>
                        <b:AccountingMonth>${credit.accounting_month}</b:AccountingMonth>
                        <b:Balance>${credit.balance}</b:Balance>
                        <b:Description>${credit.description}</b:Description>
                        <b:InvoiceNumber i:nil="true"/>
                        <b:ItemNumber>${credit.item_number}</b:ItemNumber>
                        ${payment}
                        <b:Pending>${credit.pending}</b:Pending>
                        <b:PolicyNumber>${credit.policy_number}</b:PolicyNumber>
                        <b:TransactionAmount>${credit.transaction_amount}</b:TransactionAmount>
                        <b:TransactionCode>${credit.transaction_code}</b:TransactionCode>
                        <b:TransactionEffectiveDate>${credit.transaction_effective_date}</b:TransactionEffectiveDate>
                        <b:TransactionID>${credit.transaction_id}</b:TransactionID>
                      </b:CreditItem>
                      `;
      }
    });
    let debits_xml = "";
    _.each(debits, (debit) => {
      debits_xml += `<b:DebitItem>
                        <b:ARDueDate>${debit.ar_due_date}</b:ARDueDate>
                        <b:AccountingMonth>${debit.accounting_month}</b:AccountingMonth>
                        <b:Balance>${debit.balance}</b:Balance>
                        <b:Description>${debit.description}</b:Description>
                        <b:InvoiceNumber i:nil="true"/>
                        <b:ItemNumber>${debit.item_number}</b:ItemNumber>
                        <b:Pending>${debit.pending}</b:Pending>
                        <b:PolicyNumber>${debit.policy_number}</b:PolicyNumber>
                        <b:TransactionAmount>${debit.transaction_amount}</b:TransactionAmount>
                        <b:TransactionCode>${debit.transaction_code}</b:TransactionCode>
                        <b:TransactionEffectiveDate>${debit.transaction_effective_date}</b:TransactionEffectiveDate>
                        <b:TransactionID>${debit.transaction_id}</b:TransactionID>
                    </b:DebitItem>
                      `;
    });
    let xml = `<Action_Transaction_ApplyCreditsToDebits xmlns="http://webservices.appliedsystems.com/epic/sdk/2019/01/">
            <ApplyCreditsToDebitsObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountingMonth>${date}</a:AccountingMonth>
                <a:AgencyCode>ABS</a:AgencyCode>
                <a:AssociatedAccountID>${client_id}</a:AssociatedAccountID>
                <a:AssociatedAccountTypeCode>CUST</a:AssociatedAccountTypeCode>
                <a:Credits xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/">
                    ${credits_xml}
                </a:Credits>
                <a:Debits xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_transaction/_action/_applycreditstodebits/">
                    ${debits_xml}
                </a:Debits>
                <a:MonthOption xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                    <b:OptionName>AccountingMonth</b:OptionName>
                    <b:Value>0</b:Value>
                </a:MonthOption>
            </ApplyCreditsToDebitsObject>
        </Action_Transaction_ApplyCreditsToDebits>`;
    // console.log(this.buildRequestXml(xml))
    let response = await this.post(this.buildRequestXml(xml), this.actionApplyDefaultCreditsDebits);
    // console.log(response);
  };

  static insertClient = async (client) => {
    let clientObject = this.createClientObject(client);
    let xml = `<Insert_Client xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">
                  ${clientObject}
              </Insert_Client>`;
    // console.log(xml);
    let response = await this.post(this.buildRequestXml(xml), this.insertClientAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Insert_ClientResponse"]) return false;
    if (
      !response["s:Envelope"]["s:Body"]["Insert_ClientResponse"]["Insert_ClientResult"] ||
      response["s:Envelope"]["s:Body"]["Insert_ClientResponse"]["Insert_ClientResult"] === ""
    )
      return false;
    let clientID = response["s:Envelope"]["s:Body"]["Insert_ClientResponse"]["Insert_ClientResult"];
    await this.insertActivity({
      clientID,
      code: "ADAC",
      type: "Account",
    });
    await this.updateClientContacts(clientID, client.Middle);
    return clientID;
    // }
  };

  static createClientObject = (client) => {
    let firstName = '<a:PrimaryContactFirst i:nil="true"/>';
    let lastName = '<a:PrimaryContactLast i:nil="true"/>';
    let middle = '<a:PrimaryContactMiddle i:nil="true"/>';
    let email = '<a:PrimaryContactEmail i:nil="true"/>';
    let email2 = '<a:AccountEmail i:nil="true"/>';
    let branchCode = client.location === "QC" ? "QUE" : "ABS";
    // log location passed in
    // console.log('passed in location for createClientObject is: '+client.location);
    // console.log('then the branchCode is: ' + branchCode);

    if (client.FirstName) {
      if (client.FirstName !== "") {
        // let names = split(client.name, ' ');
        // if(names[0] !== '') {
        firstName = `<a:PrimaryContactFirst>${client.FirstName}</a:PrimaryContactFirst>`;
        // }
      }
    }
    if (client.Middle) {
      if (client.Middle !== "") {
        // let names = split(client.name, ' ');
        // if(names[0] !== '') {
        middle = `<a:PrimaryContactMiddle>${client.Middle}</a:PrimaryContactMiddle>`;
        // }
      }
    }
    if (client.Last) {
      if (client.Last !== "") {
        // let names = split(client.name, ' ');
        // if(names[0] !== '') {
        lastName = `<a:PrimaryContactLast>${client.Last}</a:PrimaryContactLast>`;
        // }
      }
    }
    if (client.email && client.email !== "") {
      email = `<a:PrimaryContactEmail>${client.email}</a:PrimaryContactEmail>`;
      email2 = `<a:AccountEmail>${client.email}</a:AccountEmail>`;
    }
    return `<ClientObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
      <a:AccountName>${client.FirstName} ${client.Last}</a:AccountName>
      <a:AccountValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_client/">
        <b:Address i:nil="true" xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_shared/"/>
        <b:AddressDescription i:nil="true"/>
        <b:Comments i:nil="true"/>
        <b:Extension i:nil="true"/>
        <b:FaxCallPermission i:nil="true"/>
        <b:FaxDescription i:nil="true"/>
        <b:FaxExtension i:nil="true"/>
        <b:FaxNumber i:nil="true"/>
        <b:Number i:nil="true"/>
        <b:NumberCallPermission i:nil="true"/>
        <b:NumberDescription i:nil="true"/>
        <b:NumberType i:nil="true"/>
        <b:Structure xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/">
          <c:AgencyStructureItem>
            <c:AgencyCode>ABS</c:AgencyCode>
            <c:BranchCode>${branchCode}</c:BranchCode>
            <c:Flag>View</c:Flag>
          </c:AgencyStructureItem>
        </b:Structure>
        <b:Website i:nil="true"/>
        <b:FaxCountryCode i:nil="true"/>
        <b:PhoneCountryCode i:nil="true"/>
        <b:SiteID i:nil="true"/>
        ${email2}
        <b:AccountEmailDescription i:nil="true"/>
        <b:WebsiteDescription i:nil="true"/>
      </a:AccountValue>
      <a:AdditionalContactFirst i:nil="true"/>
      <a:AdditionalContactLast i:nil="true"/>
      <a:AdditionalContactMiddle i:nil="true"/>
      <a:AdditionalContactPrefix i:nil="true"/>
      <a:AdditionalContactSuffix i:nil="true"/>
      <a:BillingValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_client/"/>
      <a:BusinessName i:nil="true"/>
      <a:BusinessPrefix i:nil="true"/>
      <a:BusinessSuffix i:nil="true"/>
      <a:CategoriesHistoryValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_client/"/>
      <a:ClientID>0</a:ClientID>
      <a:ClientLookupCode i:nil="true"/>
      <a:ConfidentialClientAccessValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_client/"/>
      <a:DUNS i:nil="true"/>
      <a:FormatOption xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
        <b:OptionName>Individual</b:OptionName>
        <b:Value>0</b:Value>
      </a:FormatOption>
      <a:InActivateOption i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"/>
      <a:InActivateReason i:nil="true"/>
      <a:IsBenefits>false</a:IsBenefits>
      <a:IsBonds>false</a:IsBonds>
      <a:IsCommercial>false</a:IsCommercial>
      <a:IsFinancialServices>false</a:IsFinancialServices>
      <a:IsLifeAndHealth>false</a:IsLifeAndHealth>
      <a:IsOther>false</a:IsOther>
      <a:IsPersonal>true</a:IsPersonal>
      <a:IsProspectFlag>true</a:IsProspectFlag>
      <a:PrimaryContactCallPermission i:nil="true"/>
      ${email}
      <a:PrimaryContactExtension i:nil="true"/>
      ${firstName}
      ${lastName}
      ${middle}
      <a:PrimaryContactNumber i:nil="true"/>
      <a:PrimaryContactNumberType i:nil="true"/>
      <a:PrimaryContactPrefix i:nil="true"/>
      <a:PrimaryContactSuffix i:nil="true"/>
      <a:ServicingContacts i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"/>
      <a:Timestamp i:nil="true"/>
      <a:IsAgriculture>false</a:IsAgriculture>
      <a:PrimaryContactCountryCode i:nil="true"/>
      <a:AccountSourceID i:nil="true"/>
      <a:ClientGUID i:nil="true"/>
      <a:DoNotPurge>false</a:DoNotPurge>
      <a:PrimaryContactEmailDescription i:nil="true"/>
    </ClientObject>`;
  };

  static getClient = async (client, returnXML = false, check_corp = true) => {
    // console.log('getting client ', client);
    let clientID = `<a:ClientID i:nil="true"/>`;
    let name = `<a:ClientName i:nil="true"/>`;
    let firstName = `<a:FirstName i:nil="true"/>`;
    let lastName = `<a:LastName i:nil="true"/>`;
    let emailAddress = `<a:EmailAddress i:nil="true"/>`;
    // let branchCode = `<a:BranchCode i:nil="true"/>`;

    if (client.id) {
      clientID = `<a:ClientID>${client.id}</a:ClientID>`;
    }
    if (client.email && client.email !== "") {
      emailAddress = `<a:EmailAddress>${client.email}</a:EmailAddress>`;
    }
    // if (client.FirstName && client.FirstName !== "" && client.Last && client.Last !== "") {
    //   // name = `<a:ClientName>${client.FirstName} ${client.Last}</a:ClientName>`;
    //   // firstName = `<a:FirstName>${client.firstName}</a:FirstName>`;
    //   lastName = `<a:LastName>${client.lastName}</a:LastName>`;
    // }
    // Remove FirstName checking, LastName and Email only.
    if (client.Last && client.Last !== "") {
      // name = `<a:ClientName>${client.FirstName} ${client.Last}</a:ClientName>`;
      // firstName = `<a:FirstName>${client.firstName}</a:FirstName>`;
      lastName = `<a:LastName>${client.lastName}</a:LastName>`;
    }
    // if (client.location === "QC"){
    //   branchCode = `<a:BranchCode>QUE</a:BranchCode>`
    // }
    let xml = `<Get_Client xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">
            <ClientFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2009/07/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AgencyCode i:nil="true"/>
                <a:BranchCode i:nil="true"/>
                <a:City i:nil="true"/>
                ${clientID}
                <a:ClaimsAdditionalPartiesInvolvement i:nil="true"/>
                <a:ClaimsAdditionalPartiesName i:nil="true"/>
                <a:ClaimsAdditionalPartiesPhoneNumber i:nil="true"/>
                ${name}
                <a:ClientStatus>All</a:ClientStatus>
                <a:ClientType>All</a:ClientType>
                <a:CompanyClaimNumber i:nil="true"/>
                <a:DateOfLossBegins i:nil="true"/>
                <a:DateOfLossEnds i:nil="true"/>
                ${emailAddress}
                ${firstName}
                <a:InvoiceNumber i:nil="true"/>
                ${lastName}
                <a:LoanNumber i:nil="true"/>
                <a:LookupCode i:nil="true"/>
                <a:PhoneNumber i:nil="true"/>
                <a:PolicyNumber i:nil="true"/>
                <a:PriorAccountID i:nil="true"/>
                <a:RelationshipCode i:nil="true"/>
                <a:RelationshipName i:nil="true"/>
                <a:ServicingRoleCode i:nil="true"/>
                <a:ServicingRoleEmployeeCode i:nil="true"/>
                <a:StateProvinceCode i:nil="true"/>
                <a:StreetAddress i:nil="true"/>
                <a:SubmissionID i:nil="true"/>
                <a:ZipPostalCode i:nil="true"/>
                <a:AgencyDefinedCategory i:nil="true"/>
            </ClientFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Client>`;
    // console.log(this.buildRequestXml(xml));
    if (returnXML) return await this.post(this.buildRequestXml(xml), this.getClientAction, true);
    let response = await this.post(this.buildRequestXml(xml), this.getClientAction);
    // console.log(response);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"]) return false;
    let rawClient = response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"];
    if (!rawClient || rawClient === "") return false;
    let activeClient = {};
    if (check_corp) {
      // console.log({ rawClient });
      if (_.isArray(rawClient)) {
        if (rawClient.length > 1) {
          activeClient = await this.getNonCorporateClient(rawClient);
          if (activeClient === false) {
            return false;
          }
        } else {
          activeClient = rawClient[0];
        }
      } else {
        activeClient = this.parseItem(rawClient).item;
        // console.log("not array, returning activeClient");
        return activeClient;
      }
    } else {
      if (_.isArray(rawClient)) {
        activeClient = rawClient[0];
      }
    }

    let _client = {};

    each(activeClient, (item, key) => {
      let i = this.parseItem(item, key);
      _client[i.key] = i.item;
    });
    return _client;
  };
  /**
   *
   * @param {array} clients array of client objects
   * @returns {*} first client with no policies with CE&O or false if none exist
   */
  static getNonCorporateClient = async (clients) => {
    for await (let client of clients) {
      const clientPolicies = await this.getClientPolicies({
        id: client["b:ClientID"],
      });
      let hasCEO = false;
      if (clientPolicies) {
        for (let policy of Object.keys(clientPolicies)) {
          if (clientPolicies[policy].policy_type_code === "CE&amp;O") {
            //loop through next pol
            hasCEO = true;
            // console.log('has CE&O policy', clientPolicies[policy]);
          }
        }
      }
      if (hasCEO === false) {
        // console.log("client account has no CEO as policy", client, clientPolicies);
        return client;
      }
    }
    return false;
  };

  static updateClientAddress = async (client) => {
    // console.log("updateClientAddress ||| " + client.id);
    let xml = await this.getClient({ id: client.id }, true);
    // console.log(xml);
    let match = xml.match(/<b:Client[^>]*>[\s\S]*?<\/b:Client>/);
    if (match) {
      xml = match[0];
    } else {
      return;
    }
    if (xml.indexOf("<d:City>")) {
      let cityMatch = xml.match(/<d:City[^>]*>[\s\S]*?<\/d:City>/);
      if (cityMatch) {
        let cityxml = cityMatch[0];
        // console.log(cityxml);
        xml = xml.replace(cityxml, `<d:City>${client.city}</d:City>`);
      } else {
        xml = xml.replace("<d:City/>", `<d:City>${client.city}</d:City>`);
      }
    } else {
      xml = xml.replace("<d:City/>", `<d:City>${client.city}</d:City>`);
    }
    if (xml.indexOf("<d:StateOrProvinceCode>")) {
      // console.log('found province');
      let provMatch = xml.match(/<d:StateOrProvinceCode[^>]*>[\s\S]*?<\/d:StateOrProvinceCode>/);
      if (provMatch) {
        let provxml = provMatch[0];
        xml = xml.replace(provxml, `<d:StateOrProvinceCode>${client.province}</d:StateOrProvinceCode>`);
      } else {
        // console.log('replacing empty province');
        xml = xml.replace("<d:StateOrProvinceCode/>", `<d:StateOrProvinceCode>${client.province}</d:StateOrProvinceCode>`);
      }
    } else {
      // console.log('replacing empty province');
      xml = xml.replace("<d:StateOrProvinceCode/>", `<d:StateOrProvinceCode>${client.province}</d:StateOrProvinceCode>`);
    }
    if (xml.indexOf("<d:Street1>")) {
      let addressMatch = xml.match(/<d:Street1[^>]*>[\s\S]*?<\/d:Street1>/);
      if (addressMatch) {
        let addressxml = addressMatch[0];
        xml = xml.replace(addressxml, `<d:Street1>${client.address}</d:Street1>`);
      } else {
        xml = xml.replace("<d:Street1/>", `<d:Street1>${client.address}</d:Street1>`);
      }
    } else {
      xml = xml.replace("<d:Street1/>", `<d:Street1>${client.address}</d:Street1>`);
    }
    if (xml.indexOf("<d:ZipOrPostalCode>")) {
      let postalMatch = xml.match(/<d:ZipOrPostalCode[^>]*>[\s\S]*?<\/d:ZipOrPostalCode>/);
      if (postalMatch) {
        let postalxml = postalMatch[0];
        xml = xml.replace(postalxml, `<d:ZipOrPostalCode>${client.postal}</d:ZipOrPostalCode>`);
      } else {
        xml = xml.replace("<d:ZipOrPostalCode/>", `<d:ZipOrPostalCode>${client.postal}</d:ZipOrPostalCode>`);
      }
    } else {
      xml = xml.replace("<d:ZipOrPostalCode/>", `<d:ZipOrPostalCode>${client.postal}</d:ZipOrPostalCode>`);
    }
    if (xml.indexOf("<c:AccountEmail>")) {
      let postalMatch = xml.match(/<c:AccountEmail[^>]*>[\s\S]*?<\/c:AccountEmail>/);
      if (!postalMatch) {
        xml = xml.replace("<c:AccountEmail/>", `<c:AccountEmail>${client.email}</c:AccountEmail>`);
      }
    } else {
      xml = xml.replace("<c:AccountEmail/>", `<c:AccountEmail>${client.email}</c:AccountEmail>`);
    }
    // console.log(xml);
    xml = xml.replace(
      "<b:Client>",
      '<ClientObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    xml = xml.replace("</b:Client>", "</ClientObject>");
    xml = `<Update_Client xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">${xml}</Update_Client>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.updateClientAction);
    // console.log(response);
    return;
  };

  static updateClientServices = async (clientID) => {
    // console.log("updateClientServices ||| " + clientID);
    let xml = await this.getClient({ id: clientID }, true);
    // console.log(xml);
    let match = xml.match(/<b:Client[^>]*>[\s\S]*?<\/b:Client>/);
    if (match) {
      xml = match[0];
    } else {
      return;
    }
    xml = xml.replace(
      "<b:Client>",
      '<ClientObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    xml = xml.replace("</b:Client>", "</ClientObject>");
    xml = xml.replace(
      '<b:ServicingContacts xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode/><c:Role>Client Service Rep</c:Role></c:ServicingRoleItem><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode/><c:Role>Producer</c:Role></c:ServicingRoleItem></b:ServicingContacts>',
      '<b:ServicingContacts xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode>SERHO1</c:EmployeeLookupCode><c:Role>Client Service Rep</c:Role></c:ServicingRoleItem><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode>PROHO1</c:EmployeeLookupCode><c:Role>Producer</c:Role></c:ServicingRoleItem></b:ServicingContacts>'
    );
    xml = `<Update_Client xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">${xml}</Update_Client>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.updateClientAction);
    // console.log(response);
    return;
  };

  static updateClientInsured = async (clientID) => {
    // console.log("updateClientInsured ||| " + clientID);
    let xml = await this.getClient({ id: clientID }, true);
    // console.log(xml);
    let match = xml.match(/<b:Client[^>]*>[\s\S]*?<\/b:Client>/);
    if (match) {
      xml = match[0];
    } else {
      return;
    }
    // console.log(xml);
    xml = xml.replace(
      "<b:Client>",
      '<ClientObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    xml = xml.replace("</b:Client>", "</ClientObject>");
    xml = xml.replace("<b:IsProspectFlag>true</b:IsProspectFlag>", "<b:IsProspectFlag>false</b:IsProspectFlag>");
    xml = `<Update_Client xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">${xml}</Update_Client>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.updateClientAction);
    // console.log(response);
    return;
  };

  static getClientPolicies = async (client) => {
    // console.log('getting client policies ' + client.id);
    let xml = `<Get_Policy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <PolicyFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:BrokerCommissionCode i:nil="true"/>
                <a:BrokerCommissionCodeComparisonType>EqualTo</a:BrokerCommissionCodeComparisonType>
                <a:ClientID>${client.id}</a:ClientID>
                <a:ClientServicingRoleCode i:nil="true"/>
                <a:DepartmentCode i:nil="true"/>
                <a:EffectiveDateBegins i:nil="true"/>
                <a:EffectiveDateEnds i:nil="true"/>
                <a:ExpirationDateBegins i:nil="true"/>
                <a:ExpirationDateEnds i:nil="true"/>
                <a:PolicyID i:nil="true"/>
                <a:PolicyNumber i:nil="true"/>
                <a:PolicyNumberComparisonType>EqualTo</a:PolicyNumberComparisonType>
                <a:PolicyTypeCode i:nil="true"/>
                <a:ProducerCommissionCode i:nil="true"/>
                <a:ProducerCommissionCodeComparisonType>EqualTo</a:ProducerCommissionCodeComparisonType>
                <a:ServicingRoleEmployeeLookupCode i:nil="true"/>
                <a:Status>All</a:Status>
            </PolicyFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Policy>`;
    let response = await this.post(this.buildRequestXml(xml), this.getPolicyAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]["b:Policy"]) return false;
    let rawPolicies = response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]["b:Policy"];
    let policies = {};
    each(rawPolicies, (item, key) => {
      let i = this.parseItem(item, key);
      policies[i.key] = i.item;
    });
    return policies;
  };

  static updateClientContacts = async (clientID, middle = "") => {
    // console.log('updating client contacts');
    let xml = `<Get_Contact xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">
            <ContactFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2009/07/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountID>${clientID}</a:AccountID>
                <a:AccountTypeCode>CUST</a:AccountTypeCode>
                <a:ContactID i:nil="true"/>
                <a:DateOfBirthBegins i:nil="true"/>
                <a:DateOfBirthEnds i:nil="true"/>
                <a:Category i:nil="true"/>
                <a:City i:nil="true"/>
                <a:CityComparisonType>EqualTo</a:CityComparisonType>
                <a:Classification i:nil="true"/>
                <a:ClassificationComparisonType>EqualTo</a:ClassificationComparisonType>
                <a:Description i:nil="true"/>
                <a:DescriptionComparisonType>EqualTo</a:DescriptionComparisonType>
                <a:EmailAddress i:nil="true"/>
                <a:EmailAddressComparisontype>EqualTo</a:EmailAddressComparisontype>
                <a:Name i:nil="true"/>
                <a:NameComparisonType>EqualTo</a:NameComparisonType>
                <a:Phone i:nil="true"/>
                <a:PhoneComparisonType>EqualTo</a:PhoneComparisonType>
                <a:State i:nil="true"/>
            </ContactFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Contact>`;
    let response = await this.post(this.buildRequestXml(xml), this.getContactAction, true);
    let matches = response.match(/<b:Contact[^>]*>[\s\S]*?<\/b:Contact>/g);
    if (matches) {
      _.each(matches, (contactXML) => {
        let first_name = contactXML.match(/<b:FirstName[^>]*>[\s\S]*?<\/b:FirstName>/);
        if (first_name) {
          first_name = first_name[0];
          first_name = first_name.replace("<b:FirstName>", "").replace("</b:FirstName>", "");
          contactXML = contactXML.replace("<b:InFormalHeading/>", `<b:InFormalHeading>${first_name}</b:InFormalHeading>`);
        }
        // console.log(contactXML);
        contactXML = contactXML.replace(
          /<b:Contact>/g,
          '<ContactObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2017/02/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
        );
        contactXML = contactXML.replace(
          /<b:Contact>/g,
          '<ContactObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2017/02/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
        );
        contactXML = contactXML.replace(/<\/b:Contact>/g, "</ContactObject>");
        if (middle != "") {
          contactXML = contactXML.replace(/<c:MiddleName[^>]*>[\s\S]*?<\/c:MiddleName>/g, "<c:MiddleName>" + middle + "</c:MiddleName>");
        }
        contactXML = contactXML.replace(/<c:ContactVia[^>]*>[\s\S]*?<\/c:ContactVia>/g, "<c:ContactVia>Email</c:ContactVia>");
        contactXML = contactXML.replace(
          /<c:BillingContactMethod[^>]*>[\s\S]*?<\/c:BillingContactMethod>/g,
          "<c:BillingContactMethod>Email</c:BillingContactMethod>"
        );
        contactXML = contactXML.replace(
          /<c:MarketingContactMethod[^>]*>[\s\S]*?<\/c:MarketingContactMethod>/g,
          "<c:MarketingContactMethod>Email</c:MarketingContactMethod>"
        );
        contactXML = contactXML.replace(
          /<c:ServicingContactMethod[^>]*>[\s\S]*?<\/c:ServicingContactMethod>/g,
          "<c:ServicingContactMethod>Email</c:ServicingContactMethod>"
        );
        contactXML = `<Update_Contact xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">${contactXML}</Update_Contact>`;
        this.post(this.buildRequestXml(contactXML), this.updateContactAction);
      });
    }
    return;
  };

  static getPolicy = async (policy) => {
    // console.log('getting  policy ' + policy.policy_id);
    let xml = `<Get_Policy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <PolicyFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:BrokerCommissionCode i:nil="true"/>
                <a:BrokerCommissionCodeComparisonType>EqualTo</a:BrokerCommissionCodeComparisonType>
                <a:ClientID i:nil="true"/>
                <a:ClientServicingRoleCode i:nil="true"/>
                <a:DepartmentCode i:nil="true"/>
                <a:EffectiveDateBegins i:nil="true"/>
                <a:EffectiveDateEnds i:nil="true"/>
                <a:ExpirationDateBegins i:nil="true"/>
                <a:ExpirationDateEnds i:nil="true"/>
                <a:PolicyID>${policy.policy_id}</a:PolicyID>
                <a:PolicyNumber i:nil="true"/>
                <a:PolicyNumberComparisonType>EqualTo</a:PolicyNumberComparisonType>
                <a:PolicyTypeCode i:nil="true"/>
                <a:ProducerCommissionCode i:nil="true"/>
                <a:ProducerCommissionCodeComparisonType>EqualTo</a:ProducerCommissionCodeComparisonType>
                <a:ServicingRoleEmployeeLookupCode i:nil="true"/>
                <a:Status>All</a:Status>
            </PolicyFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Policy>`;
    let response = await this.post(this.buildRequestXml(xml), this.getPolicyAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]["b:Policy"]) return false;
    let rawPolicies = response["s:Envelope"]["s:Body"]["Get_PolicyResponse"]["Get_PolicyResult"]["a:Policies"]["b:Policy"];
    let policies = {};
    each(rawPolicies, (item, key) => {
      let i = this.parseItem(item, key);
      policies[i.key] = i.item;
    });
    return policies;
  };

  static getPolicyLine = async (clientID) => {
    // console.log('getting client ' + clientID);
    let xml = ``;
    let response = await this.post(this.buildRequestXml(xml));
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"]) return false;
    let rawClient = response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"];
    let _client = {};
    each(rawClient, (item, key) => {
      let i = this.parseItem(item, key);
      _client[i.key] = i.item;
    });
    return _client;
  };

  static insertPolicy = async (clientID, policy, renewal = false, branchCode) => {
    // console.log("inserting policy ", policy, renewal, branchCode);
    let status = renewal ? "REN" : "NWQ";
    let xml = `<Insert_Policy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <PolicyObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountID>${clientID}</a:AccountID>
                <a:AgencyCode>ABS</a:AgencyCode>
                <a:AgencyCommissionAmount i:nil="true"/>
                <a:AgencyCommissionPercent i:nil="true"/>
                <a:AgencyCommissionType i:nil="true"/>
                <a:AgreementID>0</a:AgreementID>
                <a:AnnualizedCommission i:nil="true"/>
                <a:AnnualizedPremium i:nil="true"/>
                <a:BillingModeOption xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                    <b:OptionName>AgencyBilled</b:OptionName>
                    <b:Value>0</b:Value>
                </a:BillingModeOption>
                <a:BranchCode>${branchCode}</a:BranchCode>
                <a:DefaultCommissionAgreement>false</a:DefaultCommissionAgreement>
                <a:DepartmentCode>PL</a:DepartmentCode>
                <a:Description>${policy.description}</a:Description>
                <a:EffectiveDate>${policy.start_date}</a:EffectiveDate>
                <a:EstimatedCommission i:nil="true"/>
                <a:EstimatedPremium i:nil="true"/>
                <a:ExpirationDate>${policy.end_date}</a:ExpirationDate>
                <a:IsProspectivePolicy>true</a:IsProspectivePolicy>
                <a:IssuingCompanyLookupCode i:nil="true"/>
                <a:IssuingLocationCode>${policy.location}</a:IssuingLocationCode>
                <a:LineEstimatedCommission i:nil="true"/>
                <a:LineEstimatedPremium i:nil="true"/>
                <a:LineTypeCode>${policy.code}</a:LineTypeCode>
                <a:PayableContractID i:nil="true"/>
                <a:PolicyID>0</a:PolicyID>
                <a:PolicyNumber>TEMPORARY POLICY NUMBER</a:PolicyNumber>
                <a:PolicyTypeCode>${policy.code}</a:PolicyTypeCode>
                <a:PrefillID i:nil="true"/>
                <a:PremiumPayableLookupCode i:nil="true"/>
                <a:PremiumPayableTypeCode i:nil="true"/>
                <a:ProfitCenterCode>E&amp;O</a:ProfitCenterCode>
                <a:Source i:nil="true"/>
                <a:StatusCode>${status}</a:StatusCode>
                <a:Timestamp i:nil="true"/>
                <a:MultiCarrierSchedule>false</a:MultiCarrierSchedule>
                <a:Downloaded i:nil="true"/>
                <a:EstimatedMonthlyCommission i:nil="true"/>
                <a:EstimatedMonthlyPremium i:nil="true"/>
                <a:TurnOffPolicyDownload>false</a:TurnOffPolicyDownload>
            </PolicyObject>
        </Insert_Policy>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.insertPolicyAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]["Insert_PolicyResult"]) return false;
    await this.updateClientServices(clientID);
    await this.insertActivity({
      clientID: clientID,
      policyID: response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]["Insert_PolicyResult"],
      code: "APOL",
      type: "Policy",
    });
    return response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]["Insert_PolicyResult"];
  };

  static updatePolicy = async (clientID) => {
    // console.log('getting client ' + clientID);
    let xml = ``;
    let response = await this.post(this.buildRequestXml(xml));
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"]) return false;
    let rawClient = response["s:Envelope"]["s:Body"]["Get_ClientResponse"]["Get_ClientResult"]["a:Clients"]["b:Client"];
    let _client = {};
    each(rawClient, (item, key) => {
      let i = this.parseItem(item, key);
      _client[i.key] = i.item;
    });
    return _client;
  };

  static updatePolicyNumber = async (policyID) => {
    // console.log('updating policy number ' + policyID);
    let xml = `<Get_Policy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <PolicyFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:BrokerCommissionCode i:nil="true"/>
                <a:BrokerCommissionCodeComparisonType>EqualTo</a:BrokerCommissionCodeComparisonType>
                <a:ClientID i:nil="true"/>
                <a:ClientServicingRoleCode i:nil="true"/>
                <a:DepartmentCode i:nil="true"/>
                <a:EffectiveDateBegins i:nil="true"/>
                <a:EffectiveDateEnds i:nil="true"/>
                <a:ExpirationDateBegins i:nil="true"/>
                <a:ExpirationDateEnds i:nil="true"/>
                <a:PolicyID>${policyID}</a:PolicyID>
                <a:PolicyNumber i:nil="true"/>
                <a:PolicyNumberComparisonType>EqualTo</a:PolicyNumberComparisonType>
                <a:PolicyTypeCode i:nil="true"/>
                <a:ProducerCommissionCode i:nil="true"/>
                <a:ProducerCommissionCodeComparisonType>EqualTo</a:ProducerCommissionCodeComparisonType>
                <a:ServicingRoleEmployeeLookupCode i:nil="true"/>
                <a:Status>All</a:Status>
            </PolicyFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Policy>`;
    let policyXML = await this.post(this.buildRequestXml(xml), this.getPolicyAction, true);
    let match = policyXML.match(/<b:Policy[^>]*>[\s\S]*?<\/b:Policy>/);
    if (match) {
      policyXML = match[0];
    } else {
      return;
    }
    // console.log(policyXML);
    policyXML = policyXML.replace(
      "<b:Policy>",
      '<PolicyObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    policyXML = policyXML.replace("</b:Policy>", "</PolicyObject>");
    policyXML = policyXML.replace("<b:PolicyNumber>TEMPORARY POLICY NUMBER</b:PolicyNumber>", `<b:PolicyNumber>${policyID}</b:PolicyNumber>`);
    policyXML = `<Update_Policy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">${policyXML}</Update_Policy>`;
    // console.log(this.buildRequestXml(policyXML));
    let response = await this.post(this.buildRequestXml(policyXML), this.updatePolicyAction);
    // console.log(response);
  };

  static getSupplementalScreen = async (lineID) => {
    console.log('getting supplemental screen ' + lineID);
    let xml = `<Get_CustomForm_SupplementalScreen xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
                  <ID>${lineID}</ID>
                  <SupplementalScreenID>-1</SupplementalScreenID>
                  <IDType>LineID</IDType>
              </Get_CustomForm_SupplementalScreen>`;
    // console.log({xml});
    let response = await this.post(this.buildRequestXml(xml), this.getSupplementalScreenAction);
    console.log('getSupplementalScreen response ------------- ');
    console.log({response});
    let pricing = null;
    let discounts = null;
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"]) return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
        "a:SupplementalScreen"
      ]
    )
      return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
        "a:SupplementalScreen"
      ]["a:FormDataValue"]
    )
      return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
        "a:SupplementalScreen"
      ]["a:FormDataValue"]["b:SupplementalScreenDataItem"]
    )
      return false;
    if (
      response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
        "a:SupplementalScreen"
      ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][0]
    ) {
      if (
        response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
          "a:SupplementalScreen"
        ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][0]["b:NonScheduledItemsValue"]
      ) {
        if (
          response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
            "a:SupplementalScreen"
          ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][0]["b:NonScheduledItemsValue"]["b:SupplementalScreenNonScheduledDataItem"]
        ) {
          pricing =
            response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
              "a:SupplementalScreen"
            ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][0]["b:NonScheduledItemsValue"]["b:SupplementalScreenNonScheduledDataItem"];
        }
      }
    }
    if (
      response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
        "a:SupplementalScreen"
      ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][1]
    ) {
      if (
        response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
          "a:SupplementalScreen"
        ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][1]["b:NonScheduledItemsValue"]
      ) {
        if (
          response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
            "a:SupplementalScreen"
          ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][1]["b:NonScheduledItemsValue"]["b:SupplementalScreenNonScheduledDataItem"]
        ) {
          discounts =
            response["s:Envelope"]["s:Body"]["Get_CustomForm_SupplementalScreenResponse"]["Get_CustomForm_SupplementalScreenResult"][
              "a:SupplementalScreen"
            ]["a:FormDataValue"]["b:SupplementalScreenDataItem"][1]["b:NonScheduledItemsValue"]["b:SupplementalScreenNonScheduledDataItem"];
        }
      }
    }
    let _pricing = {
      ea: [],
      eb: [],
      ec: [],
      ed: [],
      sp: [],
      do: [],
      a1: [],
      mp_number: null
    };
    let _discounts = [];
    each(pricing, (item, key) => {
      let i = this.parseItem(item, key);
      if (i.item.field_description === "MPNumber") {
        _pricing.mp_number = i.item.value;
      }
      if (i.item.field_description.indexOf("EA") === 0) {
        _pricing.ea.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
      if (i.item.field_description.indexOf('A1L') === 0) {
        _pricing.a1.push({
          type: i.item.field_description,
          value: i.item.value
        });
      }
      if (i.item.field_description.indexOf('EB') === 0) {
        _pricing.eb.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
      if (i.item.field_description.indexOf("EC") === 0) {
        _pricing.ec.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
      if (i.item.field_description.indexOf("ED") === 0) {
        _pricing.ed.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
      if (i.item.field_description.indexOf("SP") === 0) {
        _pricing.sp.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
      if (i.item.field_description.indexOf("DO") === 0) {
        _pricing.do.push({
          type: i.item.field_description,
          value: i.item.value,
        });
      }
    });
    each(discounts, (item, key) => {
      let i = this.parseItem(item, key);
      _discounts.push({ type: i.item.field_description, value: i.item.value });
    });
    return [_pricing, _discounts];
  };

  static getMasterPolNum = async () => {
    const supplementalScreen = await this.getSupplementalScreen(process.env.REACT_APP_EPIC_PRICING_LINE_ID);
    const masterPol = supplementalScreen[0].mp_number;
    return masterPol;
  };

  static getLine = async (policyID) => {
    // console.log('getting line ' + policyID);
    let xml = `<Get_Line xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <LineFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:PolicyID>${policyID}</a:PolicyID>
            </LineFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Line>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.getLineAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:LineID"]) return false;
    return response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:LineID"];
  };

  static updateLine = async (policyID, corp_partner, is_cyber) => {
    // console.log('getting line ' + policyID);
    let xml = `<Get_Line xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <LineFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:PolicyID>${policyID}</a:PolicyID>
            </LineFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Line>`;
    let lineXML = await this.post(this.buildRequestXml(xml), this.getLineAction, true);
    if (!lineXML) return false;
    let line = parse(lineXML);
    let _line = {};
    if (
      line["s:Envelope"] &&
      line["s:Envelope"]["s:Body"] &&
      line["s:Envelope"]["s:Body"]["Get_LineResponse"] &&
      line["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"] &&
      line["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"] &&
      line["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]
    ) {
      each(line["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"], (item, key) => {
        let i = this.parseItem(item, key);
        _line[i.key] = i.item;
      });
    } else {
      return;
    }
    let match = lineXML.match(/<b:Line[^>]*>[\s\S]*?<\/b:Line>/);
    if (match) {
      lineXML = match[0];
    } else {
      return;
    }
    lineXML = lineXML.replace(
      "<b:Line>",
      '<LineObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2017/02/_account/_policy/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    lineXML = lineXML.replace("</b:Line>", "</LineObject>");
    //  console.log('!!_line && !!_line.issuing_location_code && !!_line.line_type_code', !!_line , !!_line.issuing_location_code , !!_line.line_type_code);
    if (!!_line && !!_line.issuing_location_code && !!_line.line_type_code) {
      //  console.log('_line', _line);
      let commission_agreement = await this.getCommissionAgreementLookup(_line.line_type_code, _line.issuing_location_code, is_cyber);
      //  console.log('commission_agreement', commission_agreement);
      if (!!commission_agreement && !!commission_agreement.code && !!commission_agreement.amount) {
        //  console.log('adding commission to line');
        lineXML = lineXML.replace('<b:AgencyCommissionType i:nil="true"/>', "<b:AgencyCommissionType>%</b:AgencyCommissionType>");
        lineXML = lineXML.replace(
          '<b:AgencyCommissionPercent i:nil="true"/>',
          `<b:AgencyCommissionPercent>${commission_agreement.amount}</b:AgencyCommissionPercent>`
        );
        lineXML = lineXML.replace(
          "<b:DefaultCommissionAgreement>false</b:DefaultCommissionAgreement>",
          "<b:DefaultCommissionAgreement>true</b:DefaultCommissionAgreement>"
        );
        lineXML = lineXML.replace("<b:AgreementID>-1</b:AgreementID>", `<b:AgreementID>${commission_agreement.code}</b:AgreementID>`);
        lineXML = lineXML.replace("<b:IssuingCompanyLookupCode/>", "<b:IssuingCompanyLookupCode>ZURIN1</b:IssuingCompanyLookupCode>");
        lineXML = lineXML.replace("<b:PremiumPayableLookupCode/>", "<b:PremiumPayableLookupCode>ZURIN1</b:PremiumPayableLookupCode>");
      }
    }

    if (!is_cyber) {
      lineXML = lineXML.replace(
        '<b:ProducerBrokerCommissionsValue xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/"><c:Commissions xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/_producerbrokercommissions/"/><c:ProducerBrokerCommissionTermOption xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><d:OptionName>SingleTermCommission</d:OptionName><d:Value>0</d:Value></c:ProducerBrokerCommissionTermOption><c:ProducerBrokerScheduleTermItems xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/_producerbrokercommissions/"/></b:ProducerBrokerCommissionsValue>',
        `<b:ProducerBrokerCommissionsValue xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/">
                    <c:Commissions xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/_producerbrokercommissions/">
                        <d:CommissionItem>
                            <d:CommissionAmount i:nil="true"/>
                            <d:CommissionID>${process.env.REACT_APP_EPIC_COMMISSION_ID}</d:CommissionID>
                            <d:CommissionPercent>5.0000</d:CommissionPercent>
                            <d:CommissionType>@</d:CommissionType>
                            <d:Flag>Insert</d:Flag>
                            <d:LookupCode>${process.env.REACT_APP_EPIC_COMMISSION_LOOKUP}</d:LookupCode>
                            <d:OrderNumber>0</d:OrderNumber>
                            <d:ProducerBrokerCode>PPAY</d:ProducerBrokerCode>
                            <d:ProductionCredit>100.0000</d:ProductionCredit>
                            <d:CommissionAgreementID>${process.env.REACT_APP_EPIC_COMMISSION_AGREEMENT_ID}</d:CommissionAgreementID>
                            <d:ContractID i:nil="true"/>
                            <d:ContractName/>
                            <d:ShareRevenueAgencyCode/>
                            <d:ShareRevenueAgencyID>-1</d:ShareRevenueAgencyID>
                            <d:ShareRevenueAgencyName/>
                            <d:ShareRevenueBranchCode/>
                            <d:ShareRevenueBranchID>-1</d:ShareRevenueBranchID>
                            <d:ShareRevenueBranchName/>
                            <d:ShareRevenueDepartmentCode/>
                            <d:ShareRevenueDepartmentID>-1</d:ShareRevenueDepartmentID>
                            <d:ShareRevenueDepartmentName/>
                            <d:ShareRevenuePercent>0</d:ShareRevenuePercent>
                            <d:ShareRevenueProfitCenterCode/>
                            <d:ShareRevenueProfitCenterID>-1</d:ShareRevenueProfitCenterID>
                            <d:ShareRevenueProfitCenterName/>
                            <d:PrBrLineCommissionAnnualized i:nil="true"/>
                            <d:PrBrLineCommissionEstimated i:nil="true"/>
                            <d:OverrideCommissionAgreementPercentageOrAmount>false</d:OverrideCommissionAgreementPercentageOrAmount>
                            <d:UseCommissionAgreement>true</d:UseCommissionAgreement>
                        </d:CommissionItem>
                    </c:Commissions>
                    <c:ProducerBrokerCommissionTermOption xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                        <d:OptionName>SingleTermCommission</d:OptionName>
                        <d:Value>0</d:Value>
                    </c:ProducerBrokerCommissionTermOption>
                    <c:ProducerBrokerScheduleTermItems xmlns:d="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_line/_producerbrokercommissions/"/>
                </b:ProducerBrokerCommissionsValue>`
      );
    }
    if (corp_partner) {
      lineXML = lineXML.replace(
        '<b:AgencyDefinedCategoryItems xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"/>',
        `<b:AgencyDefinedCategoryItems xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"><c:AgencyDefinedCodeItem><c:ADCCategory>Corporate Partners</c:ADCCategory><c:ADCOption>${corp_partner}</c:ADCOption><c:Flag>Insert</c:Flag><c:AppliesTo i:nil="true"/></c:AgencyDefinedCodeItem></b:AgencyDefinedCategoryItems>`
      );
    }
    lineXML = lineXML.replace(
      `<b:ServicingContacts xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode/><c:Role>Client Service Rep</c:Role></c:ServicingRoleItem><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode/><c:Role>Producer</c:Role></c:ServicingRoleItem></b:ServicingContacts>`,
      `<b:ServicingContacts xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_common/"><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode>SERHO1</c:EmployeeLookupCode><c:Role>Client Service Rep</c:Role></c:ServicingRoleItem><c:ServicingRoleItem><c:Description/><c:EmployeeLookupCode>PROHO1</c:EmployeeLookupCode><c:Role>Producer</c:Role></c:ServicingRoleItem></b:ServicingContacts>`
    );
    lineXML = `<Update_Line xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">${lineXML}</Update_Line>`;
    //  console.log(this.buildRequestXml(lineXML));
    await this.post(this.buildRequestXml(lineXML), this.updateLineAction);
  };

  static getServiceSummary = async (policyID) => {
    // console.log('getting service summary ' + policyID);
    let xml = `<Get_Line xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <LineFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:PolicyID>${policyID}</a:PolicyID>
            </LineFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Line>`;
    let response = await this.post(this.buildRequestXml(xml), this.getLineAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:ServiceSummaries"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:ServiceSummaries"]["c:ServiceSummaryItem"])
      return false;
    if (
      !response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:ServiceSummaries"]["c:ServiceSummaryItem"][
        "c:ServiceSummaryID"
      ]
    )
      return false;
    return response["s:Envelope"]["s:Body"]["Get_LineResponse"]["Get_LineResult"]["a:Lines"]["b:Line"]["b:ServiceSummaries"]["c:ServiceSummaryItem"][
      "c:ServiceSummaryID"
    ];
  };

  static getContractLookup = async () => {
    // console.log('getting contract');
    let date = moment().format("YYYY-MM-DD");
    let xml = `<Get_Lookup xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">
            <LookupTypeObject>LinePremiumPayableContract</LookupTypeObject>
            <SearchTerms xmlns:a="http://schemas.microsoft.com/2003/10/Serialization/Arrays" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <!-- PremiumPayableTypeCode -->
                <a:string>CA</a:string>
                <!-- PremiumPayableLookupcode -->
                <a:string>ZURIN1</a:string>
                <!-- AgencyCode -->
                <a:string>ABS</a:string>
                <!-- BranchCode -->
                <a:string>ABS</a:string>
                <!-- DepartmentCode -->
                <a:string>PL</a:string>
                <!-- ProfitCenterCode -->
                <a:string>E&amp;O</a:string>
                <!-- EffectiveDate(en-US format) -->
                <a:string>${date}T00:00:00</a:string>
            </SearchTerms>
        </Get_Lookup>`;
    let response = await this.post(this.buildRequestXml(xml), this.getLookupAction);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]["a:Lookup"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]["a:Lookup"]["a:Code"]) return false;
    return response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]["a:Lookup"]["a:Code"];
  };

  static getCommissionAgreementLookup = async (type, location, is_cyber) => {
    // console.log('getting commission agreements');
    let date = moment().format("YYYY-MM-DD");
    let xml = `<Get_Lookup xmlns="http://webservices.appliedsystems.com/epic/sdk/2018/01/">
            <LookupTypeObject>LineCommissionAgreement</LookupTypeObject>
            <SearchTerms xmlns:a="http://schemas.microsoft.com/2003/10/Serialization/Arrays" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
               <a:string>ABS</a:string>
                <a:string>ABS</a:string>
                <a:string>PL</a:string>
                <a:string>DEF</a:string>
                <a:string>ZURIN1</a:string>
                <a:string>CA</a:string>
                <a:string>ZURIN1</a:string>
                <a:string>${date}T00:00:00</a:string>
                <a:string>A</a:string>
                <a:string>${type}</a:string>
                <a:string>NWQ</a:string>
                <a:string>${location}</a:string>
                <a:string>False</a:string>
            </SearchTerms>
        </Get_Lookup>`;
    let response = await this.post(this.buildRequestXml(xml), this.getLookupAction);
    // console.log(response)
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]["a:Lookup"]) return false;
    let comms = response["s:Envelope"]["s:Body"]["Get_LookupResponse"]["Get_LookupResult"]["a:Lookup"];
    comms = Array.isArray(comms) ? comms : [comms];
    let comm;
    // console.log(comms)
    if (is_cyber) {
      comm = _.find(comms, function (c) {
        return c["a:Description"].includes("Security &amp; Privacy");
      });
    } else {
      comm = _.find(comms, function (c) {
        return c["a:Description"].includes("E &amp; O Coverage");
      });
    }
    // console.log(comm);
    let comm_desc = comm["a:Description"].replace("&amp;", "^^^").split(";");
    // console.log({ comm, comm_desc });
    let description = comm_desc[0];
    let amount = comm_desc[2];
    // console.log('comm["a:Code"], description, amount', comm['a:Code'], description, amount);
    return { code: comm["a:Code"], description, amount };
  };

  static issuePolicy = async (policy, renewal = false) => {
    // console.log('issuing policy ' + policy.policy_id);
    let status = renewal ? "REN" : "NEW";
    let contractID = await this.getContractLookup();
    let lineID = await this.getLine(policy.policy_id);
    let serviceSummaryID = await this.getServiceSummary(policy.policy_id);
    let xml = `<Action_Policy_IssueNotIssuePolicy xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <IssueNotIssuePolicyObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_action/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:EffectiveDate>${policy.effective_date}</a:EffectiveDate>
                <a:ExpirationDate>${policy.expiration_date}</a:ExpirationDate>
                <a:IgnoreOpenActivities>true</a:IgnoreOpenActivities>
                <a:Lines xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_action/_issuenotissuepolicy/">
                    <b:LineItem>
                        <b:IssueNotIssuePolicyOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                            <c:OptionName>Issue</c:OptionName>
                            <c:Value>0</c:Value>
                        </b:IssueNotIssuePolicyOption>
                        <b:IssuingCompanyLookupCode>ZURIN1</b:IssuingCompanyLookupCode>
                        <b:LineID>${lineID}</b:LineID>
                        <b:PayableContractID>${contractID}</b:PayableContractID>
                        <b:PremiumPayableLookupCode>ZURIN1</b:PremiumPayableLookupCode>
                        <b:PremiumPayableTypeCode>CA</b:PremiumPayableTypeCode>
                        <b:StatusCode>${status}</b:StatusCode>
                    </b:LineItem>
                </a:Lines>
                <a:PolicyID>${policy.policy_id}</a:PolicyID>
                <a:PolicyNumber>${policy.policy_id}</a:PolicyNumber>
                <a:ServiceSummaryID>${serviceSummaryID}</a:ServiceSummaryID>
            </IssueNotIssuePolicyObject>
        </Action_Policy_IssueNotIssuePolicy>`;
    try {
      let response = await this.post(this.buildRequestXml(xml), this.issuePolicyAction);
      if (!response || !response["s:Envelope"]) return false;
      if (!response["s:Envelope"]["s:Body"]) return false;
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  static getCustomForm = async (lineID) => {
    if (!lineID) {
      return false;
    }
    let xml = `<Get_CustomForm xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <LineID>${lineID}</LineID>
         </Get_CustomForm>`;
    let response = await this.post(this.buildRequestXml(xml), this.getCustomFormAction);
    let obj = {
      LineID: lineID,
    };
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]) return false;
    obj.FormName = response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:FormName"];
    obj.FormID = response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:FormID"];
    obj.screens = [];
    if (
      response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:NonScheduledScreens"] &&
      response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:NonScheduledScreens"]
    ) {
      let screens =
        response["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:NonScheduledScreens"][
          "b:NonScheduledScreenItem"
        ];
      each(screens, (screen_item, screen_key) => {
        let fields = [];
        let fieldItem = screen_item["b:Fields"]["b:FieldItem"];
        if (fieldItem["b:FieldID"]) {
          fields.push({
            FieldID: fieldItem["b:FieldID"],
            FieldLabel: fieldItem["b:FieldLabel"],
            FieldName: fieldItem["b:FieldName"],
            FieldValue: fieldItem["b:FieldValue"],
          });
        } else {
          each(screen_item["b:Fields"]["b:FieldItem"], (field, field_key) => {
            fields.push({
              FieldID: field["b:FieldID"],
              FieldLabel: field["b:FieldLabel"],
              FieldName: field["b:FieldName"],
              FieldValue: field["b:FieldValue"],
            });
          });
        }
        obj.screens.push({
          ScreenID: screen_item["b:ScreenID"],
          ScreenName: screen_item["b:ScreenName"],
          fields,
        });
      });
    }
    return obj;
  };

  static updateCustomForm = async (form_data, lines, final_update = false) => {
    // console.log("updateCustomForm ||| " + form_data.FormName);
    // let timestamp = moment().add(1, "minutes").utc().format();
    const timestamp = await getUtcTimestamp();
    let response;
    for (let i in lines) {
      let line_xml = `<Get_CustomForm xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/"><LineID>${lines[i]}</LineID></Get_CustomForm>`;
      let line_response = await this.post(this.buildRequestXml(line_xml), this.getCustomFormAction, true);
      // const rgxp = new RegExp(`<a:Timestamp(.*)(\/)`);
      // console.log(line_response.match(rgxp)[1]);
      let xml = `
    <Update_CustomForm xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <CustomForm xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:FormID>${form_data.FormID}</a:FormID>
                <a:LineID>${lines[i]}</a:LineID>
                <a:NonScheduledScreens xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/_customform/">`;
      for (let screenIndex in form_data.screens) {
        const screen = form_data.screens[screenIndex];
        if (screen.ScreenName === "Liability" && final_update === true) {
          const line_parse = parse(line_response);
          const parser = new j2xParser({
            attributeNamePrefix: "@_",
            attrNodeName: "@", //default is false
            textNodeName: "#text",
            ignoreAttributes: true,
            cdataTagName: "__cdata", //default is false
            cdataPositionChar: "\\c",
            format: false,
            indentBy: "  ",
            suppressEmptyNode: false,
            tagValueProcessor: (a) => a, // default is a=>a
            attrValueProcessor: (a) => a, // default is a=>a
            rootNodeName: "element",
          });
          xml +=
            "<b:NonScheduledScreenItem>" +
            parser
              .parse(
                line_parse["s:Envelope"]["s:Body"]["Get_CustomFormResponse"]["Get_CustomFormResult"]["a:CustomForm"]["a:NonScheduledScreens"][
                  "b:NonScheduledScreenItem"
                ][5]
              )
              .replaceAll("<b:DataType>", '<b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">') +
            "</b:NonScheduledScreenItem>";
        } else {
          xml += `<b:NonScheduledScreenItem>
                          <b:Fields>`;
          for (let fieldIndex in screen.fields) {
            const field = screen.fields[fieldIndex];
            xml += `<b:FieldItem>
                      <b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                          <c:OptionName>TextType</c:OptionName>
                          <c:Value>5</c:Value>
                      </b:DataType>
                      <b:FieldID>${field.FieldID}</b:FieldID>
                      <b:FieldName>${field.FieldName}</b:FieldName>
                      <b:FieldValue>${field.FieldValue}</b:FieldValue>
                      <b:FieldLabel>${field.FieldLabel}</b:FieldLabel>
                  </b:FieldItem>`;
          }
          xml += `</b:Fields>
                <b:ScreenID>${screen.ScreenID}</b:ScreenID>
                <b:ScreenName>${screen.ScreenName}</b:ScreenName>
              </b:NonScheduledScreenItem>`;
        }
      }
      xml += `</a:NonScheduledScreens>
            <a:Timestamp>${timestamp}</a:Timestamp>
            </CustomForm>
        </Update_CustomForm>`;
      // console.log("update__custom", this.buildRequestXml(xml));
      // console.log({ timestamp, xml });
      response = await this.post(this.buildRequestXml(xml), this.updateCustomFormAction);
    }
    return response;
  };

  static updateLiabilityForm = async (policy_id, per, agg, coverage) => {
    // console.log("updateLiabilityForm ||| " + policy_id);
    let lineID = await this.getLine(policy_id);
    let xml = `<Get_CustomForm xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
            <LineID>${lineID}</LineID>
        </Get_CustomForm>`;
    let formXML = await this.post(this.buildRequestXml(xml), this.getCustomFormAction, true);
    // console.log(this.buildRequestXml(xml));
    // console.log(formXML);
    let match = formXML.match(/<a:CustomForm[^>]*>[\s\S]*?<\/a:CustomForm>/);
    if (match) {
      formXML = match[0];
    } else {
      return;
    }
    formXML = formXML.replace(
      "<a:CustomForm>",
      '<CustomForm xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_account/_policy/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
    );
    formXML = formXML.replace("</a:CustomForm>", "</CustomForm>");
    // console.log({policy_id, coverage, per, agg})
    if (coverage === "Cyber") {
      formXML = formXML.replace(
        `<b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>b4c6da08-ca77-4fa5-b4cb-117e359a9dc6</b:FieldID><b:FieldName>CyberAggregate</b:FieldName><b:FieldValue/><b:FieldLabel>Aggregate</b:FieldLabel></b:FieldItem><b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>49545810-e8fe-4293-b3dc-943d7f92d497</b:FieldID><b:FieldName>CyberPerClaim</b:FieldName><b:FieldValue/><b:FieldLabel>Per Claim</b:FieldLabel></b:FieldItem>`,
        `<b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>b4c6da08-ca77-4fa5-b4cb-117e359a9dc6</b:FieldID><b:FieldName>CyberAggregate</b:FieldName><b:FieldValue>${agg}</b:FieldValue><b:FieldLabel>Aggregate</b:FieldLabel></b:FieldItem><b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>49545810-e8fe-4293-b3dc-943d7f92d497</b:FieldID><b:FieldName>CyberPerClaim</b:FieldName><b:FieldValue>${per}</b:FieldValue><b:FieldLabel>Per Claim</b:FieldLabel></b:FieldItem>`
      );
    }
    if (coverage === "EO") {
      formXML = formXML.replace(
        `<b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>12fcb784-70d5-4948-8f54-90ae10288e51</b:FieldID><b:FieldName>EOAggregate</b:FieldName><b:FieldValue/><b:FieldLabel>Aggregate</b:FieldLabel></b:FieldItem><b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>f0840e93-5b0f-4076-b87e-41c26825b624</b:FieldID><b:FieldName>EOPerClaim</b:FieldName><b:FieldValue/><b:FieldLabel>Per Claim</b:FieldLabel></b:FieldItem>`,
        `<b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>12fcb784-70d5-4948-8f54-90ae10288e51</b:FieldID><b:FieldName>EOAggregate</b:FieldName><b:FieldValue>${agg}</b:FieldValue><b:FieldLabel>Aggregate</b:FieldLabel></b:FieldItem><b:FieldItem><b:DataType xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>CurrencyType</c:OptionName><c:Value>13</c:Value></b:DataType><b:FieldID>f0840e93-5b0f-4076-b87e-41c26825b624</b:FieldID><b:FieldName>EOPerClaim</b:FieldName><b:FieldValue>${per}</b:FieldValue><b:FieldLabel>Per Claim</b:FieldLabel></b:FieldItem>`
      );
    }
    formXML = `<Update_CustomForm xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">${formXML}</Update_CustomForm>`;
    // let timestamp = moment().add(1, "minutes").utc().format();
    const timestamp = await getUtcTimestamp();
    formXML = formXML.replace(/<a:Timestamp[^>]*>[\s\S]*?<\/a:Timestamp>/, `<a:Timestamp>${timestamp}</a:Timestamp>`);
    // console.log({ timestamp, formXML });
    let response = await this.post(this.buildRequestXml(formXML), this.updateCustomFormAction);
    // console.log(response);
  };

  static insertActivity = async (activity) => {
    // console.log('inserting activity ', activity);
    let timestamp = moment().utc().format();
    let policyID = `<a:AssociatedToID>0</a:AssociatedToID>`;
    if (activity.policyID) {
      policyID = `<a:AssociatedToID>${activity.policyID}</a:AssociatedToID>`;
    }

    // default all activities to open
    let closed = false;
    if (activity.code === "RENP" || activity.code === "ADAC") closed = true;

    let closedStatus = `<b:ClosedStatus i:nil="true"/>`;
    let status = { option: "Open", value: 0 };
    if (closed) {
      closedStatus = `<b:ClosedStatus>Successful</b:ClosedStatus>`;
      status = { option: "Closed", value: 1 };
    }

    // set branchCode for Quebec client
    let branchCode = activity.province === "QC" ? "QUE" : "ABS";

    let xml = `<Insert_Activity xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">
            <ActivityObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountID>${activity.clientID}</a:AccountID>
                <a:AccountTypeCode>CUST</a:AccountTypeCode>
                <a:ActivityCode>${activity.code}</a:ActivityCode>
                <a:ActivityID>0</a:ActivityID>
                <a:AgencyCode>ABS</a:AgencyCode>
                ${policyID}
                <a:AssociatedToType>${activity.type}</a:AssociatedToType>
                <a:BranchCode>${branchCode}</a:BranchCode>
                <a:CloseDetailValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/_activity/">
                    <b:ActualCost i:nil="true"/>
                    <b:ActualTimeHours i:nil="true"/>
                    <b:ActualTimeMinutes i:nil="true"/>
                    <b:ClosedReason i:nil="true"/>
                    ${closedStatus}
                    <b:IgnoreOpenTasks>false</b:IgnoreOpenTasks>
                    <b:AverageCost i:nil="true"/>
                    <b:AverageTimeHours i:nil="true"/>
                    <b:AverageTimeMinutes i:nil="true"/>
                    <b:Duration i:nil="true"/>
                </a:CloseDetailValue>
                <a:Description i:nil="true"/>
                <a:DetailValue xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/_activity/">
                    <b:ContactName i:nil="true"/>
                    <b:ContactNumberEmail i:nil="true"/>
                    <b:ContactVia i:nil="true"/>
                    <b:FollowUpEndDate>${moment().add(7, "days").utc().format()}</b:FollowUpEndDate>
                    <b:FollowUpEndTime>${moment().add(7, "days").utc().format()}</b:FollowUpEndTime>
                    <b:FollowUpStartDate>${timestamp}</b:FollowUpStartDate>
                    <b:FollowUpStartTime>${timestamp}</b:FollowUpStartTime>
                    <b:IssuingCompanyLookupCode i:nil="true"/>
                    <b:Notes xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/_activity/_common/">
                        <c:NoteItem>
                            <c:AccessLevel i:nil="true"/>
                            <c:Flag>View</c:Flag>
                            <c:NoteID>0</c:NoteID>
                            <c:NoteText i:nil="true"/>
                        </c:NoteItem>
                    </b:Notes>
                    <b:PremiumPayableLookupCode i:nil="true"/>
                    <b:PremiumPayableTypeCode i:nil="true"/>
                    <b:ReminderDate i:nil="true"/>
                    <b:ReminderTime i:nil="true"/>
                    <b:ContactPhoneCountryCode i:nil="true"/>
                    <b:Amount i:nil="true"/>
                    <b:AmountQualifier i:nil="true"/>
                    <b:Update i:nil="true"/>
                    <b:ContactID i:nil="true"/>
                </a:DetailValue>
                <a:Priority i:nil="true"/>
                <a:StatusOption xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                    <b:OptionName>${status.option}</b:OptionName>
                    <b:Value>${status.value}</b:Value>
                </a:StatusOption>
                <a:Tasks i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/_activity/" />
                <a:Timestamp i:nil="true"/>
                <a:WhoOwnerCode>PORT</a:WhoOwnerCode>
                <a:CategoryID>0</a:CategoryID>
                <a:GeneralLedgerItemIDForAssociatedToID>0</a:GeneralLedgerItemIDForAssociatedToID>
                <a:EnteredDate i:nil="true"/>
                <a:AttachmentsYesNo i:nil="true"/>
                <a:LastUpdatedDate i:nil="true"/>
                <a:ServicingContacts i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2017/01/_common/_activity/" />
                <a:WhoOwnerOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/">
                    <c:OptionName>WorkGroup</c:OptionName>
                    <c:Value>1</c:Value>
                </a:WhoOwnerOption>
                <a:AssociatedToGUID i:nil="true"/>
                <a:LineID i:nil="true"/>
                <a:SMS i:nil="true"/>
                <a:IndioSubmissionValue i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2021/01/_common/_activity/" />
            </ActivityObject>
        </Insert_Activity>`;
    // console.log(this.buildRequestXml(xml));
    let response = await this.post(this.buildRequestXml(xml), this.insertActivityAction);
    // console.log(response);
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]["Insert_PolicyResult"]) return false;
    return response["s:Envelope"]["s:Body"]["Insert_PolicyResponse"]["Insert_PolicyResult"];
  };

  static closePolicyActivities = async (activity) => {
    // console.log('closing policy ' + activity.policyID);
    let xml = `<Get_Activity xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">
            <ActivityFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountID>${activity.clientID}</a:AccountID>
                <a:AccountTypeCode>CUST</a:AccountTypeCode>
            </ActivityFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Activity>`;
    let response = await this.post(this.buildRequestXml(xml), this.getActivityAction);
    // console.log(xml)
    if (!response || !response["s:Envelope"]) return false;
    if (!response["s:Envelope"]["s:Body"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ActivityResponse"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ActivityResponse"]["Get_ActivityResult"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ActivityResponse"]["Get_ActivityResult"]["a:Activities"]) return false;
    if (!response["s:Envelope"]["s:Body"]["Get_ActivityResponse"]["Get_ActivityResult"]["a:Activities"]["b:Activity"]) return false;
    let rawClient = response["s:Envelope"]["s:Body"]["Get_ActivityResponse"]["Get_ActivityResult"]["a:Activities"]["b:Activity"];
    let _activity = {};
    each(rawClient, (item, key) => {
      let i = this.parseItem(item, key);
      _activity[i.key] = i.item;
    });
    let activityID;
    each(_activity, (a) => {
      if (a.associated_to_id === activity.policyID && a.activity_code === "APOL" && a.status_option.value === 0) {
        activityID = a.activity_id;
      }
    });
    // console.log(activityID);
    if (activityID) {
      let xml = `<Get_Activity xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">
            <ActivityFilterObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2011/01/_get/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <a:AccountID>${activity.clientID}</a:AccountID>
                <a:AccountTypeCode>CUST</a:AccountTypeCode>
                <a:ActivityID>${activityID}</a:ActivityID>
            </ActivityFilterObject>
            <PageNumber>0</PageNumber>
        </Get_Activity>`;
      let activityXML = await this.post(this.buildRequestXml(xml), this.getActivityAction, true);
      // console.log(activityXML);
      let match = activityXML.match(/<b:Activity[^>]*>[\s\S]*?<\/b:Activity>/);
      if (match) {
        activityXML = match[0];
      } else {
        return;
      }
      // console.log(activityXML);
      activityXML = activityXML.replace(
        "<b:Activity>",
        '<ActivityObject xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2011/01/_common/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">'
      );
      activityXML = activityXML.replace("</b:Activity>", "</ActivityObject>");
      activityXML = activityXML.replace("<c:ClosedStatus/>", "<c:ClosedStatus>Successful</c:ClosedStatus>");
      activityXML = activityXML.replace(
        '<b:StatusOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>Open</c:OptionName><c:Value>0</c:Value></b:StatusOption>',
        '<b:StatusOption xmlns:c="http://schemas.appliedsystems.com/epic/sdk/2009/07/_common/"><c:OptionName>Closed</c:OptionName><c:Value>1</c:Value></b:StatusOption>'
      );
      activityXML = `<Update_Activity xmlns="http://webservices.appliedsystems.com/epic/sdk/2017/02/">${activityXML}</Update_Activity>`;
      // console.log(this.buildRequestXml(activityXML));
      await this.post(this.buildRequestXml(activityXML), this.updateActivityAction);
    }
  };

  /**
   * getAttachment: Function getting uploaded attachment.
   * @param {string} attachmentID uuid generated for the uploaded attachment.
   * @returns {boolean} success/fail
   */
  static getAttachment = async (attachmentID) => {
    // function didn't work as intended
    return false;
    const xml = getAttachmentXml(attachmentID);
    const attachment = await this.post(this.buildRequestXml(xml), this.getAttachmentAction);
    console.log({ attachment });
    return true;
  };

  /**
   * insertAttachment: Function linking uploaded attachment to a specific account.
   * @param {int} AccountID account/client ID to insert the attachment on
   * @param {int} PolicyID the Policy ID the certificate is generated for.  Used for PDF naming
   * @param {string} AttachmentID uuid generated for the uploaded attachment.  Used to link attachment to account attachments
   * @param {int} AttachmentSize Bytes of file attached, required field in xml call.
   * @returns {boolean} success/fail
   */
  static insertAttachment = async (AccountID, PolicyID, AttachmentID, AttachmentSize, fileDescription) => {
    const xml = insertAttachmentXml(AccountID, PolicyID, AttachmentID, AttachmentSize, fileDescription);
    const insertAttachmentXmlResponse = await this.post(this.buildRequestXml(xml), this.insertAttachmentAction);
    return insertAttachmentXmlResponse;
  };

  /**
   * uploadAttachments: Function to send Certificate (or other attachment) to epic
   * @param {object} policy object of the policy
   * @param {object} quoteDetails
   * @param {object} pricing
   * @returns {object} attachmentID: uuid of uploaded file, fileSize: file size in bytes
   */
  static uploadAttachments = async (policy, quoteDetails, pricing, locale) => {
    const { file64, fileSize } = await this.generateCertificatePdf(policy, quoteDetails, pricing, locale);
    const xml = uploadAttachmentXml(file64);
    // console.log({ xml });
    const response = await this.postUpload(xml, this.uploadAttachmentAction);
    // console.log('response in uploadattachments: ', { response });
    await this.writeActivityToLogs(
      JSON.stringify(response),
      "response from uploadAttachments",
      JSON.stringify({
        policy,
        quoteDetails,
        pricing,
      }),
      "_response_uploadAttachments"
    );
    // console.log({ response });
    if (!response) return false;
    if (!response["http://tempuri.org/0"]) return false;
    if (!response["http://tempuri.org/0"]["s:Envelope"]) return false;
    if (!response["http://tempuri.org/0"]["s:Envelope"]["s:Body"]) return false;
    if (!response["http://tempuri.org/0"]["s:Envelope"]["s:Body"]["Upload_Attachment_FileResponse"]) return false;
    if (!response["http://tempuri.org/0"]["s:Envelope"]["s:Body"]["Upload_Attachment_FileResponse"]["Upload_Attachment_FileResult"]) return false;
    return {
      attachmentID: response["http://tempuri.org/0"]["s:Envelope"]["s:Body"]["Upload_Attachment_FileResponse"]["Upload_Attachment_FileResult"],
      fileSize: fileSize,
      file64: file64,
    };
  };

  /**
   * generateCertificatePdf: Function gathers information used in Policy Certificate and then generates it.
   * @param {object} policy
   * @param {object} quoteDetails
   * @param {object} pricing
   * @returns {object} file64: base64 pdf Certificate, fileSize: file size in bytes
   */
  static generateCertificatePdf = async (policy, quoteDetails, pricing, locale) => {
    const LineID = await this.getLine(policy.id)
      .then((lineID) => {
        return lineID;
      })
      .catch((e) => {
        console.log(e);
      });
    const CustomForm = await this.getCustomForm(LineID);

    const contentType = "application/pdf";
    // console.log('Data in GCPDF: ',{locale, CustomForm, quoteDetails, policy, pricing});
    const blob = await pdf(
      <CertificateGeneratorPage locale={locale} CustomForm={CustomForm} quoteDetails={quoteDetails} policy={policy} pricing={pricing} />
    ).toBlob();

    // console.log('Blob in GCPDF: ',blob);

    const file = new File([blob], "Name", {
      type: contentType,
    });
    const proimseFile64 = (file) => {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
          temporaryFileReader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result.replace("data:", "").replace(/^.+,/, ""));
        };
        temporaryFileReader.readAsDataURL(file);
      });
    };

    const file64 = await proimseFile64(file);
    // console.log('file64 in GCPDF: ', file64, file.size);
    return { file64: file64, fileSize: file.size };
  };

  /** sendEmail -- email the certificates as attached PDF's based on base64
   *
   * @param {*} attachments array of objects -> file64, fileSize, policyNumber, fileDescription used in /mailer php file
   * @param {string} locale 'FR' or 'EN'
   * @param {object} policy policy object
   */
  static sendEmail = async (attachments, quote, locale) => {
    const policies = [];
    for (let attachment of attachments) {
      policies.push(attachment.policyNumber);
    }
    console.log(policies);
    // const subject = "Certificates Generated for Policies " + policies.join();
    const subject = translateString(locale, "Your ABS Policy");
    const mailto = quote.email;
    const firstname = quote.FirstName;
    const coverage = [];
    for (let policy of quote.policies) {
      coverage.push(translateString(locale, policy.description));
    }
    const params = JSON.stringify({
      attachments,
      subject,
      mailto,
      coverage: coverage.join(", "),
      firstname,
      locale,
    });
    Axios.post("https://" + window.location.host + "/mailer/", params, {
      headers: { "Content-Type": "application/json" },
      crossDomain: true,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));
  };
}
