import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";

import _ from "lodash";

import { connect } from "react-redux";
import { fetchQuote, saveQuote, clearQuote } from "../redux/actions/quote";
import { fetchLocale, setLocale } from "../redux/actions/locale";
import { getQuote, getLocale } from "../redux/reducers";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import moment from "moment";
import { ontarioStatHolidays } from "../Assets/data/statHolidays";
import GeneralModal from "./GeneralModal";
import EpicAPI from "../Assets/api/epic";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
  };
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrice: true,
      hamburger: false,
      showModal: false,
    };
  }

  componentDidMount() {
    const { quote, fetchQuote, locale, fetchLocale } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

    if (!locale) {
      fetchLocale();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, locale, setLocale } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
    if (!_.isEqual(locale, prevProps.locale)) {
      console.log("!_.isEqual(locale, prevProps.locale)");
      this.setState({ locale: locale });
    }
    if (!locale) {
      setLocale("EN");
      this.setState({ locale: "EN" });
    }
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }
  handleHamburger = () => {
    if (this.state.hamburger === false) {
      this.setState({ hamburger: true });
    } else {
      this.setState({ hamburger: false });
    }
  };
  checkAvailability = () => {
    const now = moment().utc().subtract(4, "hours").format("DDMMYYYY");
    const { quote } = this.props;
    const nameTest = quote && quote.email === "test@test.test";
    const nowHour = moment().utc().subtract(4, "hours").format("HH");
    const hoursOfOperation = ["09", "10", "11", "12", "13", "14", "15", "16"];
    const statFormatted = ontarioStatHolidays.map((stat) => moment(stat).format("DDMMYYYY"));
    if (statFormatted.includes(now) || !hoursOfOperation.includes(nowHour) || nameTest) {
      this.triggerWorkHoursModal();
    } else {
      window.location.href = translateString(this.props.locale, "https://advocisinsurance.ca/contact/");
    }
  };
  triggerWorkHoursModal = () => {
    this.setState({ showModal: true });
  };
  deletePolicy = async () => {
    // EpicAPI.
    const { clearQuote } = this.props;
    clearQuote();
  };

  render() {
    const { locale, setLocale } = this.props;
    return (
      <Container fluid className="headerClass" as="header">
        <Row>
          <Col>
            <div className="logo">{translateJSXElement(locale, "header.logo")}</div>
          </Col>
          <Col xs="auto" className="rightNav">
            <div className={this.state.hamburger ? "hamburger active" : "hamburger"} onClick={this.handleHamburger}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={this.state.hamburger ? "menu-items active" : "menu-items"}>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  if (locale === "EN") {
                    setLocale("FR");
                  } else {
                    setLocale("EN");
                  }
                }}>
                {locale === "EN" ? "FR" : "EN"}
              </a>
              {translateJSXElement(locale, "header.menuItemAboutUs")}
              <a
                href="/#"
                onClick={() => {
                  this.checkAvailability();
                }}>
                {translateString(locale, "Talk To A Broker")}
              </a>
            </div>
          </Col>
        </Row>
        <GeneralModal show={this.state.showModal}>
          <h2>Talk to a Broker</h2>
          <p>We're sorry but no one is available to assist you at this time. Our hours of operation are </p>

          <p>Monday to Friday - 9am to 5pm EST</p>

          <p>We are closed for statutory holidays and on weekends</p>

          <p>
            If you would like to continue with your transaction with us without speaking with a broker, please click "Yes" otherwise please click "No"
            and your transaction will be cancelled
          </p>
          <div className="d-flex justify-content-between mt-2">
            <Button
              variant="alert"
              // as={"a"}
              // href="https://www.advocisinsurance.ca/"
              onClick={async () => {
                await this.deletePolicy();
                window.location.href = translateString(locale, "https://advocisinsurance.ca/");
              }}>
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}>
              Yes
            </Button>
          </div>
        </GeneralModal>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote,
  saveQuote,
  clearQuote,
  fetchLocale,
  setLocale,
})(Header);
// export default withRouter(Header);
