import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel, FormCheck, ButtonGroup, Button, ToggleButton, Spinner } from "react-bootstrap";
import ToolTip from "./ToolTip";
import PrintFormGroup from "./PrintFormGroup";
import { getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../redux/actions/quote";
import _ from "lodash";
import moment from "moment";
import xmlescape from "xml-escape";
import { FormCheckbox, FormInput, FormRadio } from "shards-react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
  };
};

export class PrintFormLogic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dependencyValue: this.props.condition ? this.props.condition : null,
      activeValue: null,
      checkedItems: [],
    };
  }

  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else {
      this.setQuoteState(quote);
      this.setState({ activeValue: quote[this.props.controlId] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    if (!_.isEqual(quote, prevProps.quote)) {
      this.setQuoteState(quote);
      this.setState({ activeValue: quote[this.props.controlId] });
    }
  }

  setQuoteState(quote) {
    this.setState({ ...quote });
  }

  handleChange = (key, val) => {
    this.handleConditional(val);
    const { quote, saveQuote } = this.props;
    const obj = { ...quote };
    obj[key] = val;
    saveQuote(obj);
  };

  handleRadioCheck = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const { quote, saveQuote } = this.props;
    const obj = { ...quote };
    obj[name] = value;

    if (name === "advocisMember") {
      obj["advocisMemberIdButtonLabel"] = "";
      if (value === "no") {
        console.log("Advocis member set to NO, resetting validation");
        obj["advocisMemberValid"] = true; // Set to true for "no" to pass validation
        obj["memberId"] = "";
      } else {
        console.log("Advocis member set to YES, requiring validation");
        obj["advocisMemberValid"] = false;
      }
    }

    saveQuote(obj);

    // Also update the component state for immediate UI feedback
    if (name === "advocisMember") {
      this.setState({
        advocisMember: value,
        advocisMemberValid: value === "no", // Valid if "no"
        advocisMemberIdButtonLabel: "",
        memberId: value === "no" ? "" : this.state.memberId,
      });
    }
  };

  handleCheckChange = (e) => {
    this.handleConditional(e.target.checked);

    const name = e.target.name;
    const value = e.target.value;
    let checkedItems = this.state[name];
    _.remove(checkedItems, (n) => !n);
    if (e.target.checked) {
      checkedItems = _.concat(checkedItems, value);
    } else {
      checkedItems = _.remove(checkedItems, (n) => !(n === value));
    }
    this.handleChange(name, _.compact(checkedItems));
  };

  handleToggleChange = (e) => {
    //if (e.target.name !== "cyberPrivacy") {
    this.handleConditional(e.target.checked);
    // const parent = e.target.closest(
    //   '[data-parent="' + this.props.parent + '"]'
    // );
    // if (!e.target.checked && parent) {
    //   parent.classList.remove("active");
    // } else {
    //   if (parent && parent.classList) parent.classList.add("active");
    // }
    // if (parent) this.props.handleChange(parent.getAttribute("data-id"), e.target.checked);

    this.handleChange(e.target.name, e.target.checked);
    //}
  };

  formatMoney = (a, locale) => {
    if (locale === "EN") return "$ " + a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    else
      return (
        a
          .toString()
          .replace(/\./g, ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " $"
      );
  };
  // handleButtonChange = (key, val) => {
  //   this.handleConditional(val);
  //   this.handleChange(key, val);
  // };
  formatName = (name, value) => {
    // const regName = /[^a-zA-Z]/g;
    // value = value.replace(regName, "");
    // value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    // value = value.replace(/\s+/g, "");
    //this.setState({ [name]: value });
    value = value.trim();
    // this.setState({ activeValue: value });
    this.setState({ [name]: value });
  };

  handleConditional = (val) => {
    this.setState({ activeValue: val });
  };

  // activateButton = e => {
  //   console.log(e.target);
  //   let val = e.target.innerHTML;
  //   if (e.target.classList.contains("active")) {
  //     e.target.classList.remove("active");
  //     this.handleConditional("");
  //     val = "";
  //   } else {
  //     e.target.parentElement.querySelectorAll(".btn").forEach(a => {
  //       a.classList.remove("active");
  //     });
  //     e.target.classList.add("active");
  //     this.handleConditional(val);
  //   }
  //   this.handleButtonChange(e, val);
  // };

  getFormControl = (formControl, id) => {
    let value = this.state[id];
    if (_.isNil(value) || value === "") {
      if (formControl.default) {
        value = formControl.default;
        this.handleChange(id, formControl.default);
        // this.handleConditional(formControl.default);
      } else {
        value = "";
      }
    }
    const { locale } = this.props;

    switch (formControl.type) {
      case "text":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              type="text"
              value={value}
              onChange={(e) => this.handleChange(id, e.target.value)}
              placeholder={formControl.placeholder ? translateString(locale, formControl.placeholder) : null}
              maxLength={formControl.maxLength ? formControl.maxLength : 256}
            />
          </div>
        );
      case "name":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              type="text"
              value={value}
              onChange={(e) => this.handleChange(id, e.target.value)}
              placeholder={formControl.placeholder ? formControl.placeholder : null}
              maxLength={formControl.maxLength ? formControl.maxLength : 256}
            />
          </div>
        );
      case "number":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              type="number"
              value={value}
              onChange={(e) => this.handleChange(id, e.target.value)}
              placeholder={formControl.placeholder ? formControl.placeholder : null}
              min="0"
            />
          </div>
        );
      case "textarea":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              as="textarea"
              value={value}
              onChange={(e) => this.handleChange(id, e.target.value)}
              placeholder={formControl.placeholder ? formControl.placeholder : null}
              rows="8"
              maxLength={500}
            />
          </div>
        );
      case "email":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              type="email"
              value={value}
              placeholder={formControl.placeholder ? formControl.placeholder : null}
              onChange={(e) => this.handleChange(id, e.target.value)}
            />
          </div>
        );
      case "select":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              onChange={(e) => this.handleChange(id, e.target.value)}
              placeholder=""
              as="select"
              value={!!value ? value : "default"}>
              {formControl.options.map((option, i) => {
                if (i === 0) {
                  return (
                    <option key={"option_" + option} value="default">
                      {/* {translateString(locale,option)} */}
                      {translateString(locale, option)}
                    </option>
                  );
                } else {
                  return (
                    <option key={"option_" + option} value={option}>
                      {/* {translateString(locale,option)} */}
                      {translateString(locale, option)}
                    </option>
                  );
                }
              })}
            </FormControl>
          </div>
        );
      case "date":
        const today = new Date().toISOString().split("T")[0];
        const maxDate = moment().add(60, "years").format("YYYY-MM-DD");
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              required
              onChange={(e) => this.handleChange(id, e.target.value)}
              type="date"
              min={today}
              max={maxDate}
              value={value}
              name={id}
              lang={locale === "FR" ? "fr-CA" : "en-US"}
            />
          </div>
        );
      case "toggle":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormGroup controlId={(id ? id : "") + "_Check"} className={value ? "toggle active" : "toggle"} name={id}>
              <FormLabel className="toggleStyle" />
              <FormCheck onChange={this.handleToggleChange} name={id} checked={value} className="flex" />
              <FormLabel key={id + "_Label"}>{translateString(locale, formControl.formLabel)}</FormLabel>
              {formControl.toolTip ? (
                <ToolTip
                  textColor="#01A665"
                  hoverColor="#01A665"
                  tipText={translateString(locale, formControl.toolTip[0].text)}
                  primaryOrigin={formControl.toolTip[0].origin}
                  verticalOrigin={formControl.toolTip[0].vertical}
                  horizonalOrigin={formControl.toolTip[0].horizontal}
                  learnMore={translateString(locale, formControl.toolTip[0].learnMore)}
                  learnMoreText={translateString(locale, formControl.toolTip[0].learnMoreText)}
                  infoID={id + "Coupon"}
                  size="18px"
                />
              ) : null}
            </FormGroup>
            {formControl.subLabel && <p className="cyberDisclaimer">{translateString(locale, formControl.subLabel)}</p>}
          </div>
        );
      case "checkbox":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            {formControl[formControl.type][0].options.length === 1 ? (
              <FormGroup controlId={(id ? id : "") + "_Check" + 0} className="checkList" key={id + "_checkList" + 0}>
                <FormLabel key={id + "_checkBoxStyle"} className="checkboxStyle" />
                <FormCheck
                  onChange={this.handleToggleChange}
                  key={id + "_Check" + 0}
                  name={id}
                  value={formControl[formControl.type][0].options[0]}
                  checked={_.indexOf(this.state[id], formControl[formControl.type][0].options[0]) > -1}
                />
                <FormLabel key={id + "_Label" + 0}>{formControl[formControl.type][0].options[0]}</FormLabel>
                {formControl.toolTip ? (
                  <ToolTip
                    textColor="#01A665"
                    hoverColor="#01A665"
                    tipText={translateString(locale, formControl.toolTip[0].text)}
                    primaryOrigin={formControl.toolTip[0].origin}
                    verticalOrigin={formControl.toolTip[0].vertical}
                    horizonalOrigin={formControl.toolTip[0].horizontal}
                    infoID={id + "Coupon"}
                    size="18px"
                  />
                ) : null}
              </FormGroup>
            ) : (
              formControl[formControl.type][0].options.map((option, j) => {
                return (
                  <FormGroup controlId={(id ? id : "") + "_Check" + j} className="checkList" key={id + "_checkList" + j}>
                    <FormCheck
                      onChange={this.handleCheckChange}
                      key={id + "_Check" + j}
                      name={id}
                      value={option}
                      checked={_.indexOf(this.state[id], option) > -1}
                    />
                    <FormLabel key={id + "_Label" + j}>{translateString(locale, option)}</FormLabel>
                  </FormGroup>
                );
              })
            )}
          </div>
        );
      case "buttons":
        // console.log(this.state[id]);
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <ButtonGroup className="radioWrap" key={this.props.parent + "_ButtonGroup"}>
              {formControl[formControl.type][0].options.map((option, j) => (
                <Button
                  name={id}
                  variant="radioButtons"
                  key={this.props.parent + "_radio" + j}
                  onClick={
                    this.state[id] === option
                      ? () => {
                          this.handleChange(id, "");
                        }
                      : () => {
                          this.handleChange(id, option);
                        }
                  }
                  className={this.state[id] && this.state[id] === option ? "active" : ""}>
                  {/^\d*$/.test(option) ? this.formatMoney(option, locale) : translateString(locale, option)}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        );
      case "radios":
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <ToggleButtonGroup
              name={id}
              value={id === "manitoba" ? (this.state.manitoba !== undefined ? this.state.manitoba : null) : this.state[id]}
              defaultValue="No"
              className={formControl.type + " FieldWrapper"}>
              {formControl.options.map((option, j) => (
                <ToggleButton
                  key={j}
                  id={`radio-${j}`}
                  type="radio"
                  value={option}
                  variant="outline-light"
                  onChange={this.handleRadioCheck}
                  checked={this.state[id] === option}>
                  {translateString(locale, option)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        );

      case "textButtonCombined":
        // console.log(this.state[id]);
        return (
          <div className={formControl.type + " FieldWrapper"}>
            <FormControl
              name={id}
              autoFocus={formControl.autoFocus}
              type="text"
              value={value}
              // onChange={(e) => this.handleChange(id, e.target.value)}
              onChange={(e) => {
                // Special handling for memberId field to reset validation status
                if (id === "memberId") {
                  const { quote, saveQuote } = this.props;
                  const obj = { ...quote };
                  obj[id] = e.target.value;
                  obj.advocisMemberValid = false;
                  saveQuote(obj);
                } else {
                  this.handleChange(id, e.target.value);
                }
              }}
              placeholder={formControl.placeholder ? translateString(locale, formControl.placeholder) : null}
              maxLength={formControl.maxLength ? formControl.maxLength : 256}
            />
            <Button
              name={formControl.buttonControlId}
              variant="basicButton"
              key={this.props.parent + "_radio" + id}
              onClick={formControl.buttonOnClick}
              disabled={formControl.buttonDisabled}
              className={""}>
              {translateString(locale, formControl.buttonText)}
            </Button>
            <label className="IDbuttonLabel">{translateString(locale, formControl.buttonLabel)}</label>
          </div>
        );

      default:
        return "";
    }
  };

  render() {
    //map with 2 diff data types
    const { data, parent, i, className, j, condition } = this.props;
    const { locale } = this.props;

    return this.props.activeValue === this.state.dependencyValue ? (
      data.FormControl ? (
        <>
          <FormGroup
            data-condition={condition}
            data-parent={parent}
            key={parent + "Group_" + i}
            controlId={data.controlId}
            data-id={data.controlId}
            className={className ? className : null}>
            {/* <div>{this.props.activeValue ? this.props.activeValue : "No active Props Value"}</div>
            <div>{this.state.dependencyValue ? this.state.dependencyValue : "No dependency Value"}</div>
            <div>{this.state.activeValue ? this.state.activeValue : "No Active Value"}</div> */}
            {this.props.data.description ? <div className="description">{translateString(locale, this.props.data.description)}</div> : null}
            {this.props.children}
            {data.formLabel ? (
              <>
                <FormLabel key={parent + "Label_" + i}>
                  {translateString(locale, data.formLabel)}
                  {data.subLabel && <p>{data.subLabel}</p>}
                </FormLabel>
                {data.toolTip ? (
                  <ToolTip
                    textColor="#01A665"
                    hoverColor="#01A665"
                    tipText={translateString(locale, data.toolTip[0].text)}
                    primaryOrigin={data.toolTip[0].origin}
                    verticalOrigin={data.toolTip[0].vertical}
                    horizonalOrigin={data.toolTip[0].horizontal}
                    learnMore={data.toolTip[0].learnMore}
                    infoID={parent + "Coupon_" + i}
                    size="18px"
                  />
                ) : null}
              </>
            ) : null}
            {this.getFormControl(data.FormControl[0], data.controlId)}
            {data.FormControl[0].dependencies && data.controlId !== "advocisMember" && data.controlId !== "greatwayMember" ? (
              <PrintFormGroup
                className="conditional"
                activeValue={this.state.activeValue}
                dependency={true}
                parent={data.controlId}
                data={data.FormControl[0].dependencies}
                handleChange={this.handleChange}
              />
            ) : null}
          </FormGroup>
          {data.FormControl[0].dependencies && (data.controlId === "advocisMember" || data.controlId === "greatwayMember") ? (
            <PrintFormGroup
              className="conditional"
              activeValue={this.state.activeValue}
              dependency={true}
              parent={data.controlId}
              data={data.FormControl[0].dependencies}
              handleChange={this.handleChange}
            />
          ) : null}
        </>
      ) : (
        <>
          <FormGroup
            data-parent={parent}
            data-condition={condition}
            key={parent + "Group_" + j}
            controlId={data.controlId}
            className={className ? className : null}
            data-id={data.controlId}>
            {/* <div>{this.props.activeValue ? this.props.activeValue : "No active Props Value"}</div>
              <div>{this.state.dependencyValue ? this.state.dependencyValue : "No dependency Value"}</div>
              <div>{this.state.activeValue ? this.state.activeValue : "No Active Value"}</div> */}
            {this.props.data.description ? <div className="description">{this.props.data.description}</div> : null}
            {this.props.children}
            {data.formLabel ? (
              <>
                <FormLabel key={parent + "Label_" + i}>
                  {data.formLabel}
                  {data.subLabel && <p>{data.subLabel}</p>}
                </FormLabel>
                {data.toolTip ? (
                  <ToolTip
                    textColor="#01A665"
                    hoverColor="#01A665"
                    tipText={translateString(locale, data.toolTip[0].text)}
                    primaryOrigin={data.toolTip[0].origin}
                    verticalOrigin={data.toolTip[0].vertical}
                    horizonalOrigin={data.toolTip[0].horizontal}
                    infoID={parent + "Coupon_" + i}
                    size="18px"
                  />
                ) : null}
              </>
            ) : null}
            {this.getFormControl(data.FormControl[0], data.controlId)}
            {data.FormControl[0].dependencies && data.controlId !== ("advocisMember" || "greatwayMember") ? (
              <PrintFormGroup
                className="conditional"
                activeValue={this.state.activeValue}
                dependency={true}
                parent={data.controlId + "_dependency"}
                data={data.FormControl[0].dependencies}
                handleChange={this.handleChange}
              />
            ) : null}
          </FormGroup>
          {data.FormControl[0].dependencies && data.controlId === ("advocisMember" || "greatwayMember") ? (
            <PrintFormGroup
              className="conditional"
              activeValue={this.state.activeValue}
              dependency={true}
              parent={data.controlId + "_dependency"}
              data={data.FormControl[0].dependencies}
              handleChange={this.handleChange}
            />
          ) : null}
        </>
      )
    ) : null;
    // <FormGroup controlId={this.props.data.controlId} className={this.props.data.className ? this.props.data.className : null}>
    //   {this.props.children}
    //   {this.props.data.formLabel ? <FormLabel>{this.props.data.formLabel}</FormLabel> : null}
    //   {this.getFormControl()}
    // </FormGroup>
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
})(PrintFormLogic);
