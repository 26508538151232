import React, { Component } from "react";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import { Row, Col, Container } from "react-bootstrap";
import PrintFormGroup from "../../components/PrintFormGroup";
import Coupon from "../../components/Coupon";
import discounts from "../../Assets/data/discountsData";
import steps from "../../Assets/data/stepsData";
import PricingDetails from "../../components/PricingDetails";

import _ from "lodash";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';

import moment from "moment";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
  };
};
export class QuoteDiscounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: discounts,
      isLoading: false,
      prevPage: "/quote/coverages",
      nextPage: "/quote/review",
      currentStep: 2,
      steps: steps,
      showPrice: true,
    };
  }

  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

    const isCoverageCOnly = quote.unlicensedPlanners && !quote.iiroc && !quote.lifeInsurance ? true : false;

    if (isCoverageCOnly) {
      const licensedYearsComponet = document.querySelector('[data-id="firstLicensed"]');
      // licensedYearsComponet.style.display = "none";
      licensedYearsComponet.remove();
    } else {
      let licensedYears = discounts[0]["firstLicensed"][0]["details"][0]["FormControl"][0]["options"];

      if (licensedYears.length === 1) {
        let year = moment().format("YYYY");
        while (year > 2013) {
          if (year > 2014) {
            licensedYears.push(year + "");
          } else {
            licensedYears.push(year + " or Before");
          }
          year--;
        }
        console.log(licensedYears);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, locale, fetchLocale  } = this.props;

    if (!locale) fetchLocale();
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }
  validateForm() {
    const { quote } = this.props;
    // const isCoverageCOnly = this.state.isCoverageConly;
    // console.log(quote);
    const isCoverageCOnly = quote.unlicensedPlanners && !quote.iiroc && !quote.lifeInsurance ? true : false;
    // const advocisMember = quote && quote.advocisMember && quote.advocisMember !== "";
    const firstLicensed = isCoverageCOnly === true ? quote && true : quote && quote.firstLicensed && quote.firstLicensed !== "default";
    // const firstLicensed = quote && quote.firstLicensed && quote.firstLicensed !== "default";

    // let advocisMemberId = true;
    // if (advocisMember && quote.advocisMember === "yes") {
    //   if (!quote.memberId || quote.memberId === "") {
    //     advocisMemberId = false;
    //   }
    // }

    // return advocisMember && firstLicensed && advocisMemberId;
    return  firstLicensed;
  }

  handleChange = (event, key) => {
    // console.log(event.target)
    // this.setState({
    //   [event.target.id]: event.target.value
    // });
  };
  handleCoupon = (coupon) => {
    coupon = this.state.coupon + " " + coupon;
    this.setState({ coupon });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push(this.state.nextPage);
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { showPrice, steps, currentStep, prevPage, isLoading } = this.state;
    const { locale } = this.props;
    console.log('discounts state', this.state);

    return (
      <Container className="Discounts" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={steps} currentStep={currentStep} />
            <div className="mobilePricing">
              <h1 className="chevron">{translateString(locale,'Get a Quote')}</h1>
              {showPrice ? <PricingDetails /> : null}
            </div>
            <h3>{translateString(locale,'Discounts')}</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="discountsFields">
                {/* {this.state.discounts.map((discount) => {
                    return (
                      Object.keys(discount).map((discountId, i) => {
                        // console.log(discount[discountId][0]);
                        return (
                          <PrintFormGroup
                            parent="discounts"
                            data={discount[discountId][0].details[0]}
                            key={discountId + "_" + i}
                            handleChange={this.handleCheckboxChange}
                          />
                        )
                      })
                    )
                  })
                  } */}
                <PrintFormGroup parent="discounts" data={this.state.discounts} handleChange={this.handleChange} />
              </div>
              <FormBottomNav
                disabled={!this.validateForm()}
                isLoading={isLoading}
                prevText={translateString(locale,"Go Back")}
                prevPage={prevPage}
                className="Line Green top"
                required={["Advocis member information", "Year you were first licensed"]}></FormBottomNav>
              {/* <Link className="tempNext" to={nextPage}> Force Next Step</Link> */}
            </form>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar">
            <Coupon handleCoupon={this.handleCoupon} />
            {showPrice ? <PricingDetails /> : null}
          </Col>
        </Row>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
})(QuoteDiscounts);
