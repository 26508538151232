import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../redux/actions/quote";
import { fetchPricing } from "../redux/actions/pricing";
import { getQuote, getPricing, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";

import taxRates from "../Assets/data/taxRates";
import { loadPricing, currencyFormat, calculateDiscount } from "../Assets/data/pricing";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state),
  };
};
export class PricingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponDiscount: 0,
      show: true,
      showTip: false,
      responsiveShow: false,
      broker_fee: 0,
      apa_fee: 0,
      tax: 0,
      total: 0,
      pre_tax_total: 0,
      coupon: { type: "", value: 0 },
      licensedDiscount: null,
      memberDiscount: null,
    };
  }

  handleClose = () => {
    const show = false;
    this.setState({ show });
  };

  handleExpand = () => {
    if (this.state.responsiveShow === false) {
      this.setState({ responsiveShow: true });
    } else {
      this.setState({ responsiveShow: false });
    }
    // this.props.handleHeaderPadding(this.state.responsiveShow)
  };

  componentDidMount = () => {
    const { quote, fetchQuote, pricing, fetchPricing } = this.props;
    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setState({ quote });
      if (quote.hasOwnProperty("coupon")) {
        this.setState({ coupon: { ...quote.coupon } });
      }
    }

    if (!pricing || _.isEmpty(pricing)) fetchPricing();
    else this.setState({ pricing });

    if (!document.querySelector(".headerClass").classList.contains("fixedPadding")) {
      document.querySelector(".headerClass").classList.add("fixedPadding");
    }
    this.calculateTotal();
    this.getTaxes();
  };

  componentDidUpdate(prevProps, prevState) {
    const { quote, pricing, locale, fetchLocale } = this.props;
    const { prices, coupon } = this.state;

    // console.log('pricing in coverage: ',pricing);

    if (!locale) fetchLocale();

    if (!!quote && !!quote.coupon) {
      this.setCoupon();
    }
    if (!_.isEqual(quote, prevProps.quote)) {
      this.setState({ quote });
      this.setCoupon();
    }
    if (!!quote && !!quote.coupon && !_.isEqual(quote.coupon, coupon)) {
      this.setCoupon();
    }
    if (!_.isEqual(coupon, prevState.coupon)) {
      this.calculateTotal();
    }
    if (!_.isEqual(pricing, prevProps.pricing)) {
      this.setState({ pricing });
      this.calculateTotal();
    }

    if (!_.isEqual(this.state.quote, prevState.quote)) {
      this.getTaxes();
      this.getPricing();
      this.calculateTotal();
    }
    if ((!prices || _.isEmpty(prices)) && this.state.quote && pricing) {
      if (
        quote.lifeInsurance ||
        quote.iiroc ||
        quote.unlicensedPlanners ||
        // quote.licensedAdmin ||
        quote.directorsAndOfficers ||
        quote.cyberPrivacy ||
        quote.lifeInsuranceLite
      ) {
        this.getPricing();
      }
    }

    if (!_.isEqual(prices, prevState.prices)) {
      this.calculateTotal();
    }
  }

  getPricing = () => {
    console.log("getting pricing - quote, pricing", this.state.quote, this.state.pricing);
    const { quote, pricing, tax } = this.state;
    let prices = loadPricing(quote, pricing, tax);

    if (!_.isEqual(prices, this.state.prices)) {
      this.setState({ prices });
    }
  };
  getTaxes = () => {
    const { quote } = this.state;
    let tax;
    if (quote && quote.location) {
      tax = taxRates[quote.location];
    }
    this.setState({ tax });
  };

  setCoupon = () => {
    const { quote } = this.props;
    // console.log('changing coupon');
    if (!!quote.coupon && !_.isEqual(quote.coupon, this.state.coupon)) {
      this.setState({ coupon: quote.coupon });
    }
  };

  calculateTotal = () => {
    // console.log('recalculating');
    const { prices, tax } = this.state;
    let total = 0;
    let pre_tax_total = 0;
    let taxable_total = 0;
    let zurich_total = 0;
    let dfcf_total = 0;

    const a1Object = _.find(prices, (price) => price.label.toLowerCase().indexOf("lite") > 0) ?? null;

    _.each(prices, (price) => {
      total += price.amount;
      if (price.amount > 0) {
        if (price.label.toLowerCase().indexOf("cyber") < 0) {
          zurich_total += price.zurich;
          dfcf_total += price.amount - price.zurich;
        }
        taxable_total += price.zurich;
      }
    });

    let {
      totalDiscount,
      couponDiscount,
      memberDiscount,
      licensedDiscount,
      couponPct,
      memberPct,
      licensedPct,
      couponZurichDiscount,
      licensedZurichPct,
    } = calculateDiscount(this.props.quote, zurich_total, dfcf_total, a1Object);

    taxable_total = taxable_total - couponZurichDiscount - licensedZurichPct - memberDiscount;

    this.setState({ couponDiscount, memberDiscount, licensedDiscount, couponPct, memberPct, licensedPct });
    total = total - totalDiscount;

    pre_tax_total = total;

    let taxes = 0;
    _.each(tax, (t) => {
      taxes += taxable_total * (t.tax / 100);
    });
    total += taxes;
    this.setState({ total, pre_tax_total, taxable_total });
  };

  render() {
    const { prices, total, quote, tax, memberPct, licensedPct, taxable_total } = this.state;

    // console.log('prices in coverage render: ',prices);
    // console.log('state in coverage render: ', this.state);

    const { locale } = this.props;

    if (!quote) return <></>;
    if (quote && !quote.location) return <Redirect to="/quote" />;
    if (!prices || _.size(prices) === 0 || prices[0].msg) return <div />;

    let taxes = [];
    _.each(tax, (t) => {
      taxes.push({
        ...t,
        amount: currencyFormat(taxable_total * (t.tax / 100), locale),
      });
    });

    return (
      <>
        <div className={this.state.responsiveShow ? "pricing-details active" : "pricing-details"}>
          <div className="pricing-details__mobileHeader" onClick={this.handleExpand}>
            <div className="cost">Total Cost</div>
            {total && <div className="price">{currencyFormat(total, locale)}</div>}
          </div>
          <div className="pricing-details__Wrapper">
            <div className="pricing-details__header">
              <h2 className="hasToolTip">{translateString(locale, "Pricing Details")}</h2>
            </div>
            <div className="pricing-details__body">
              {this.state.showTip ? (
                <div className="pricing-details__group">
                  <p>
                    <em>Please select a plan from the list at left to see your premiums.</em>
                  </p>
                </div>
              ) : null}
              {prices.map((price, i) => (
                <div className="pricing-details__group" key={i}>
                  <div className="pricing-details__items">
                    <div className="pricing-details__item number">
                      <div className="label">
                        {translateString(locale, price.label)}
                        <br />
                        {translateString(locale, "Coverage Premiums")}
                      </div>
                      {price && !!price.amount && <div className="value">{currencyFormat(price.amount, locale)}</div>}
                      {price && !price.amount && <div className="value">{locale === "EN" ? "$ 0.00" : "0,00 $"}</div>}
                    </div>
                    <div className="pricing-details__item number">
                      {price && price.limit && (
                        <div className="label successText">
                          ({currencyFormat(price.limit, locale, 0)} {translateString(locale, "per claim limit")})
                        </div>
                      )}
                    </div>
                    <div className="pricing-details__item number" />
                  </div>
                </div>
              ))}
              {(!!this.state.licensedDiscount || this.state.coupon.value > 0 || !!this.state.memberDiscount) && (
                <div className="pricing-details__group">
                  <div className="pricing-details__items">
                    <div className="pricing-details__item number">
                      <div className="label alertText bold">{translateString(locale, "Discounts")}</div>
                    </div>
                    {!!this.state.coupon.value > 0 && (
                      <div className="pricing-details__item number">
                        <div className="label alertText">
                          {translateString(locale, "Coupon Code:")} {this.state.coupon.type}
                        </div>
                        <div className="value alertText">-{currencyFormat(this.state.couponDiscount, locale)}</div>
                      </div>
                    )}
                    {!!this.state.memberDiscount && (
                      <div className="pricing-details__item number">
                        <div className="label alertText">
                          {translateString(locale, "Advocis Member Discount")} ({memberPct}%)
                        </div>
                        <div className="value alertText">-{currencyFormat(this.state.memberDiscount, locale)}</div>
                      </div>
                    )}
                    {!!this.state.licensedDiscount && (
                      <div className="pricing-details__item number">
                        <div className="label alertText">
                          {translateString(locale, "New Advisor Discount")} ({licensedPct}%)
                        </div>
                        <div className="value alertText">-{currencyFormat(this.state.licensedDiscount, locale)}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="pricing-details__group">
                <div className="pricing-details__items">
                  {taxes.map((t, i) => (
                    <div className="pricing-details__item number" key={i}>
                      <div className="label">{t.label}</div>
                      <div className="value">{t.amount}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="pricing-details__footer">
              <div className="pricing-details__item total number">
                <div className="label">{translateString(locale, "Total Due")}</div>
                {total && <div className="value">{currencyFormat(total, locale)}</div>}
              </div>
              <div className="pricing-details__session">
                * {translateString(locale, "quote is valid for this web session only, prices subject to change")}
              </div>
            </div>
          </div>
        </div>
        {this.state.responsiveShow ? <div className="modal-backdrop"></div> : null}
      </>
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote,
  saveQuote,
  fetchPricing,
  fetchLocale,
})(PricingDetails);
