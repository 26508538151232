import React, { Component } from "react";
import { FormGroup, FormLabel, FormCheck, Row, Col, Container } from "react-bootstrap";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import steps from "../../Assets/data/checkoutStepsData";
import PolicyDetails from "../../components/PolicyDetails";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";
import { getFormLines, getForms, getPricing, getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";
import _ from "lodash";
import _checkoutData from "../../Assets/data/checkoutData";
import moment from "moment";
import Axios from "axios";
import EpicAPI from "../../Assets/api/epic";
import taxRates from "../../Assets/data/taxRates";
import { calculateDiscount, loadPerClaimAggregate, loadPricing } from "../../Assets/data/pricing";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
    pricing: getPricing(state),
  };
};

export class CheckoutConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmEmail: false,
      currentStep: 3,
      steps: steps,
      optIn: false,
      agree: false,
      isLoading: false,
      prevPage: "/checkout/licensing",
      nextPage: "/checkout/review",
      broker_fee: 0,
      apa_fee: 0,
      prices: [],
    };
  }

  componentDidMount() {
    const { quote, fetchQuote, forms, fetchForms, lines, locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

    if (lines && !_.isEmpty(lines)) {
      fetchForms(lines[0]);
      this.setState(lines);
    } else this.getLineIDs();

    if (forms && !_.isEmpty(forms)) this.setFormsState(forms);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, forms, lines, pricing, locale } = this.props;

    if (!_.isEqual(lines, prevProps.lines)) this.setState(lines);
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
    if (!_.isEqual(pricing, prevProps.pricing)) this.saveQuotePricing();
    if (!_.isEqual(forms, prevProps.forms)) this.setFormsState(forms);
    //console.log(pricing);
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
    this.saveQuotePricing();
  }

  saveQuotePricing = () => {
    // console.log("calculating total");
    const { quote, pricing, saveQuote } = this.props;

    if (quote && !_.isEmpty(quote) && pricing && !_.isEmpty(pricing)) {
      let tax = [{ tax: 0 }];
      if (quote && quote.location) {
        tax = taxRates[quote.location];
      }

      let prices = loadPricing(quote, pricing);
      this.setState({ prices });

      let total = 0;
      let pre_tax_total = 0;
      let taxable_total = 0;
      let zurich_total = 0;
      let dfcf_total = 0;
      // console.log(prices);
      _.each(prices, (price) => {
        total += price.amount;
        if (price.amount > 0) {
          if (price.label.toLowerCase().indexOf("cyber") < 0) {
            zurich_total += price.zurich;
            dfcf_total += price.amount - price.zurich;
          }
          taxable_total += price.zurich;
        }
      });

      const a1Object = _.find(prices, (price) => price.label.toLowerCase().indexOf("lite") > 0) ?? null;

      let pre_discount_total = total;

      let {
        totalDiscount,
        couponDiscount,
        memberDiscount,
        licensedDiscount,
        couponPct,
        memberPct,
        licensedPct,
        couponZurichDiscount,
        licensedZurichPct,
      } = calculateDiscount(this.props.quote, zurich_total, dfcf_total, a1Object);

      taxable_total = taxable_total - couponZurichDiscount - licensedZurichPct - memberDiscount;

      this.setState({ couponDiscount, memberDiscount, licensedDiscount, couponPct, memberPct, licensedPct });
      total = total - totalDiscount;

      pre_tax_total = parseFloat(total.toFixed(2));

      let taxes = 0;
      _.each(tax, (t) => {
        taxes += taxable_total * (t.tax / 100);
      });
      taxes = parseFloat(taxes.toFixed(2));
      total += taxes;

      total = parseFloat(total.toFixed(2));

      let _quote = { ...quote, pre_tax_total, total, taxes, pre_discount_total, firstQuoteNumber: null, taxable_total };
      saveQuote(_quote);
    }
  };

  setFormsState(forms) {
    let checkoutData = _checkoutData;
    // console.log(forms);
    this.setState({
      forms,
      checkoutData,
    });
  }

  getLineIDs = async () => {
    const { quote, saveFormLines } = this.props;
    let lines = [];
    if (quote && quote.activePolicies) {
      for (let p of quote.activePolicies) {
        let lineID = await EpicAPI.getLine(p);
        lines.push(lineID);
      }
    }
    saveFormLines(lines);
  };

  validateForm() {
    return this.state.agree;
  }
  handleCheckChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };
  handleSubmit = async (event) => {
    const { saveForms, pricing, quote } = this.props;
    event.preventDefault();
    // console.log(this.state);
    const { forms, agree } = this.state;

    let IPAddress = "";
    try {
      // set isLoading to true during submitting
      this.setState({ isLoading: true });
      let IPData = await Axios.post("https://geolocation-db.com/json/");
      if (IPData && IPData.data && IPData.data.IPv4) {
        IPAddress = IPData.data.IPv4;
      }
    } catch (e) {
      // console.log('failed to load user IP address');
    }
    const mp_number = await EpicAPI.getMasterPolNum();
    // console.log('test new mp_number:', mp_number);
    let _forms = forms;
    // console.log(_forms.screens);
    _.each(_forms.screens, (s) => {
      if (s.ScreenName === "Acknowledgment") {
        _.each(s.fields, (f) => {
          if (f.FieldName === "ApplicationSignature") f.FieldValue = "Signed Via App";
          if (f.FieldName === "Date") f.FieldValue = moment().format("M/D/YYYY");
          if (f.FieldName === "Signature") f.FieldValue = agree ? "Y" : "N";
          if (f.FieldName === "IPAddress") f.FieldValue = IPAddress;
        });
      }
      //MPNumber

      if (s.ScreenName === "Master Policy Number" && pricing) {
        _.each(s.fields, (f) => {
          // if (f.FieldName === "PolNumber") f.FieldValue = pricing.mp_number;
          if (f.FieldName === "PolNumber") f.FieldValue = mp_number ? mp_number : "ZCAN8614136-06";
        });
      }
      //Clear Liability data
      if (s.ScreenName === "Liability" && pricing && pricing.mp_number) {
        _.each(s.fields, (f) => {
          if (f.FieldName === "EOPerClaim") f.FieldValue = "";
          if (f.FieldName === "EOAggregate") f.FieldValue = "";
          if (f.FieldName === "CyberPerClaim") f.FieldValue = "";
          if (f.FieldName === "CyberAggregate") f.FieldValue = "";
        });
      }
    });

    this.setState({ isLoading: true });
    try {
      // this.setState({ isLoading: false });
      saveForms(_forms)
        .then((d) => {
          //Liability Screen - Per Claim and Aggregates
          // console.log(quote.policies);
          _.each(quote.policies, (q) => {
            // console.log(q);
            let { per, agg, coverage } = loadPerClaimAggregate(q, pricing);
            // console.log({ per, agg, coverage });
            EpicAPI.updateLiabilityForm(q.id, per, agg, coverage);
          });
          this.props.history.push(this.state.nextPage);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      // console.log(e);
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { locale } = this.props;
    return (
      <Container className="Review" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                <PolicyDetails />
              </div>
              <h3>{translateString(locale, "Acknowledgement and Consent")}</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <h4>{translateString(locale, "Acknowledgements")}</h4>
              <p>
                {translateString(
                  locale,
                  "Please note that this is a claims-made policy. You must report any potential incident(s)/claim(s) to the Insurer prior to the expiry of the policy term. At such point as you cease practice in the industry, the policy provides for limited or no coverage beyond the termination date of your certificate of insurance. Options are available to extend the period beyond which you may report claims based on past professional services."
                )}
              </p>
              <p>
                {translateString(
                  locale,
                  "The undersigned applicant declares that, to the best of his/her knowledge and belief, the statements set forth herein are true and correct and that reasonable efforts have been made to obtain sufficient information to facilitate the proper and accurate completion of this application form. The undersigned further agrees that if any significant change in the condition of the applicant is discovered between the date of the application and the effective date of the policy, which would render this application inaccurate or incomplete, notice of such change will be reported immediately in writing to the insurer. Although the signing of this application form does not bind the applicant to purchase the insurance, the undersigned agrees that this form and the information furnished herein shall be the basis of the contract should a policy be issued, and this application will become part of the policy."
                )}
              </p>
              <h4>{translateString(locale, "Personal Information Consent")}</h4>
              <p>
                {translateString(
                  locale,
                  ' As part of my application for insurance, I hereby consent to the brokerage firm named below (the "Broker") and the Advocis Protective Association collecting, using and disclosing personal information* required for purposes of considering my application for new or renewal insurance coverage and of administering the professional liability insurance program sponsored by the Advocis Protective Association.'
                )}
              </p>
              <p>
                {" "}
                {translateString(
                  locale,
                  "The Broker, on behalf of the Advocis Protective Association, is authorized to collect, use, and disclose personal information and provide such personal information to third parties, as required, including insurance companies, for the purposes of program administration, insurance requirements and all related activities including the marketing of the APA program. The Broker may also be required or permitted to disclose such personal information pursuant to relevant privacy or other laws."
                )}
              </p>
              <p>
                {" "}
                {translateString(
                  locale,
                  'The undersigned applicant also agrees that their personal information, including any email address, telephone and fax number, may be shared by The Financial Advisors Association of Canada (“TFAAC”) and TFAAC group entities operating either under the brand name Advocis, or as separate legal entities ("TFAAC" Group Entity) for the purpose of marketing, including telemarketing, the products and services of 1{")"} TFAAC Group Entity, including, but not limited to, the provisions of information on APA and other memberships, education programs, conferences, Advocis Corporate Partnership/Corporate Sponsorship program and regulatory issues and 2{")"} third parties that may be of interest to you. For further information about the TFAAC Privacy Policy, please refer to the Advocis.ca website.'
                )}
              </p>
              <p>
                {" "}
                {translateString(
                  locale,
                  "If I wish to review personal information* pertaining to my application or policy maintained by the Broker, obtain copies of the Broker's privacy policies or standards, or make other enquiries or express concerns to the Broker, I understand that I may do so by contacting the Broker’s Privacy Officer: Advocis Broker Services Inc. 700-10 Lower Spadina Ave Toronto ON M5V 2Z2."
                )}
              </p>
              <p>
                {translateString(
                  locale,
                  '* Personal information has the meaning given it by the "Personal Information Protection and Electronic Documents Act."'
                )}
              </p>
              {translateJSXElement(locale, "review.apaDeclarations")}
              <FormGroup controlId="agree">
                <FormCheck onChange={this.handleCheckChange} />
                <FormLabel>
                  <span className="bold">
                    {translateString(
                      locale,
                      "I agree that all personal information that I provide to the Broker is complete and accurate, and I consent to the collection, use, and disclosure of the personal information as set forth above."
                    )}
                  </span>
                </FormLabel>
              </FormGroup>
              <div className="Line Green top">
                {translateString(
                  locale,
                  "The applicant declares that, to the best of his/her knowledge and belief, the statements set forth herein are true and correct. The applicant agrees that all personal information that provided to the Broker is complete and accurate. Furthermore if any significant change in the condition of the applicant is discovered between the date of the application and the effective date of the policy, which would render this application inaccurate or incomplete, notice of such change will be reported immediately in writing to the insurer."
                )}
              </div>
              <FormBottomNav
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                prevText={translateString(locale, "Start Over")}
                prevPage="/checkout"
                className="Line Green top"
                required={["Agree to terms"]}></FormBottomNav>
              {/* <Link className="tempNext" to={this.state.nextPage}> Force Next Step</Link> */}
            </form>
          </Col>
          <Col lg={"auto"} className="sideBar">
            <PolicyDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchForms,
  saveForms,
  saveFormLines,
})(CheckoutConsent);
