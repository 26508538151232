import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import steps from "../../Assets/data/checkoutStepsData";
import PolicyDetails from "../../components/PolicyDetails";
import { connect } from "react-redux";
import { fetchQuote } from "../../redux/actions/quote";
import { fetchForms } from "../../redux/actions/forms";
import { getFormLines, getForms, getPricing, getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString } from "../../Assets/helpers/translations/translations";
import _checkoutData from "../../Assets/data/checkoutData";
import _checkoutDataD from "../../Assets/data/checkoutDataD";
import _ from "lodash";
import { calculateDiscount, loadPricing } from "../../Assets/data/pricing";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
    pricing: getPricing(state),
  };
};

export class CheckoutReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 4,
      steps: steps,
      isLoading: false,
      prevPage: "/checkout/consent",
      nextPage: "/checkout/payment",
      isDPolicy: false,
      isOtherPolicy: false,
    };
  }

  componentDidMount() {
    const { quote, fetchQuote, forms, fetchForms, locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    else {
      const { isDPolicy, isOtherPolicy } = this.getPolicyType(quote);
      this.setState({ isDPolicy, isOtherPolicy });
    }
    if (!quote || _.isEmpty(quote)) fetchQuote();
    if (!forms || _.isEmpty(forms)) fetchForms();
  }

  componentDidUpdate(prevProps) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) {
      const { isDPolicy, isOtherPolicy } = this.getPolicyType(quote);
      this.setState({ isDPolicy, isOtherPolicy });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.props.history.push(this.state.nextPage);
  };

  getPolicyType = (quote) => {
    let isDPolicy = false;
    let isOtherPolicy = false;

    if (quote && quote.policies) {
      // console.log("quote.policies in review", quote.policies);
      let policyTypes = quote.policies.map((policy) => policy.code);
      policyTypes.forEach((policy) => {
        switch (policy.substr(0, 2)) {
          case "EA":
          case "EB":
          case "EC":
          case "A1":
            isOtherPolicy = true;
            break;
          case "ED":
            isDPolicy = true;
            break;
          default:
            break;
        }
      });
    }

    // console.log("isDPolicy, isOtherPolicy", isDPolicy, isOtherPolicy);
    return { isDPolicy, isOtherPolicy };
  };

  calculateDiscounts = () => {
    const { quote, pricing } = this.props;

    if (!quote || !pricing) {
      return {
        totalDiscount: 0,
        couponDiscount: 0,
        memberDiscount: 0,
        licensedDiscount: 0,
        couponPct: 0,
        memberPct: 0,
        licensedPct: 0,
      };
    }
    const prices = loadPricing(quote, pricing);

    let zurich_total = 0;
    let dfcf_total = 0;

    const a1Object = prices.find((price) => price.label.toLowerCase().indexOf("lite") > 0) ?? null;

    prices.forEach((price) => {
      if (price.amount > 0) {
        if (price.label.toLowerCase().indexOf("cyber") < 0) {
          zurich_total += price.zurich;
          dfcf_total += price.amount - price.zurich;
        }
      }
    });

    const discounts = calculateDiscount(quote, zurich_total, dfcf_total, a1Object);

    return discounts;
  };

  renderDeclarationItem = (item, index, quote, locale, policyType) => {
    if (item && typeof item === "object") {
      // Get the key (Answer1/DecD1, etc.)
      const itemKey = Object.keys(item)[0];

      if (item[itemKey] && Array.isArray(item[itemKey])) {
        const nestedItem = item[itemKey][0];

        if (nestedItem && nestedItem.details && Array.isArray(nestedItem.details)) {
          const controlId = nestedItem.details[0].controlId;
          const formLabel = nestedItem.details[0].formLabel;

          // Get the answer value from quote or show a placeholder
          const answerValue = quote[controlId] ? translateString(locale, quote[controlId]) : <span className="text-muted">Not answered</span>;

          return (
            <div key={`${policyType}-${index}`} className="review-row">
              <span>{translateString(locale, formLabel)}</span>
              <span>{answerValue}</span>
            </div>
          );
        }
      }
    }

    // Return null for items that don't match the expected structure
    return null;
  };

  formatMoney = (a, locale) => {
    if (locale === "EN") return "$ " + a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    else
      return (
        a
          .toString()
          .replace(/\./g, ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " $"
      );
  };

  render() {
    const { locale, quote } = this.props;
    const { isLoading, isDPolicy, isOtherPolicy } = this.state;

    const discounts = this.calculateDiscounts();

    const { licensedPct, memberPct, totalDiscountPct, licensedDFCFPct, couponPct } = discounts;

    console.log("quote on review page:", quote);
    console.log("Calculated discounts in Review:", discounts);

    return (
      <Container className="Review" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                <div style={{ display: "none" }}>
                  <PolicyDetails />
                </div>
              </div>
              <h3>{translateString(locale, "Review")}</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <h4>{translateString(locale, "Summary")}</h4>

              {quote && (
                <div className="review-container">
                  {/* who are you */}
                  <div className="review-who-are-you">
                    <div className="review-section">
                      <h5>{translateString(locale, "Who Are You:")}</h5>
                      <div className="review-row">
                        <span>{translateString(locale, "First Name")}</span> <span>{quote.FirstName}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Middle Name")}</span> <span>{quote.Middle}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Last Name")}</span> <span>{quote.Last}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Your email")}</span> <span>{quote.email}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Province of Residence")}</span> <span>{quote.Province}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Are you licensed to sell products in Manitoba?")}</span> <span>{quote.manitoba}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Advois Member ID:")}</span> <span>{quote.memberId}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "When do you need your coverage to start?")}</span> <span>{quote.startDate}</span>
                      </div>
                    </div>
                  </div>
                  {/* coverages */}
                  <div className="review-coverages">
                    <div className="review-section">
                      <h5>{translateString(locale, "Coverages:")}</h5>
                      {quote.cyberPrivacy && (
                        <div className="review-row">
                          <span>{translateString(locale, "Cyber/Security and Privacy Liability")}</span>
                          <span>{this.formatMoney(quote.cyberPrivacyPrice, locale)}</span>
                        </div>
                      )}
                      {quote.lifeInsurance && (
                        <div className="review-row">
                          <span>{translateString(locale, "Life Insurance / Mutual Funds")}</span>
                          <span>{this.formatMoney(quote.lifeInsurancePrice, locale)}</span>
                        </div>
                      )}
                      {quote.lifeInsuranceLite && (
                        <div className="review-row">
                          <span>{translateString(locale, "Life Insurance / Mutual Funds - Lite")}</span>
                          <span>{this.formatMoney(quote.lifeInsuranceLitePrice, locale)}</span>
                        </div>
                      )}
                      {quote.iiroc && (
                        <div className="review-row">
                          <span>{translateString(locale, "IIROC Licensees (Securities/Mutual Funds)")}</span>
                          <span>{this.formatMoney(quote.iirocPrice, locale)}</span>
                        </div>
                      )}
                      {quote.unlicensedPlanners && (
                        <div className="review-row">
                          <span>{translateString(locale, "Unlicensed Planners/Fee for Service")}</span>
                          <span>{this.formatMoney(quote.unlicensedPlannersPrice, locale)}</span>
                        </div>
                      )}
                      {quote.unlicensedPlannersCheckbox && quote.unlicensedPlannersCheckbox.length > 0 && (
                        <div className="review-row">
                          <span className="designations-title">{translateString(locale, "Designation(s):")}</span>
                          <span className="designations">
                            {quote.unlicensedPlannersCheckbox.map((item, index) => {
                              return <span key={`designations-${index}`}>{item}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.TextBox3 && quote.TextBox3.length > 0 && (
                        <div className="review-row">
                          <span className="designations-title">{translateString(locale, "Other Designations")}</span>
                          <span className="designations">{quote.TextBox3}</span>
                        </div>
                      )}
                      {quote.licensedAdmin && (
                        <div className="review-row">
                          <span>{translateString(locale, "Licensed Administrative Assistants")}</span>
                          <span>{this.formatMoney(quote.licensedAdminPrice, locale)}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* discounts */}
                  <div className="review-discounts">
                    <div className="review-section">
                      <h5>{translateString(locale, "Discounts:")}</h5>
                      {quote.memberId && quote.advocisMember === "yes" && typeof memberPct === "number" && memberPct > 0 && (
                        <div className="review-row">
                          <span>{translateString(locale, "Advocis Member Discount")}</span>
                          <span>({memberPct}%)</span>
                        </div>
                      )}
                      {quote.coupon && typeof couponPct === "number" && couponPct > 0 && (
                        <div className="review-row">
                          <span>{translateString(locale, "Coupon Code")}</span>
                          <span>
                            {quote.coupon.type}({quote.coupon.value}%)
                          </span>
                        </div>
                      )}
                      {quote.firstLicensed && (
                        <div className="review-row">
                          <span>{translateString(locale, "What year were you first licensed for Life and/or Mutual Funds?")}</span>
                          <span>{quote.firstLicensed}</span>
                        </div>
                      )}
                      {typeof licensedPct === "number" && licensedPct > 0 && (
                        <div className="review-row">
                          <span>{translateString(locale, "New Advisor Discount")}</span>
                          <span>({licensedPct}%)</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* declarations */}
                  <div className="review-declarations">
                    <div className="review-section">
                      <h5>{translateString(locale, "Declarations:")}</h5>

                      {(isOtherPolicy || isDPolicy) && (
                        <>
                          {isOtherPolicy &&
                            _checkoutData &&
                            _checkoutData.map((item, index) => this.renderDeclarationItem(item, index, quote, locale, "other"))}

                          {isDPolicy &&
                            _checkoutDataD &&
                            _checkoutDataD.map((item, index) => this.renderDeclarationItem(item, index, quote, locale, "policy-d"))}
                        </>
                      )}
                      {(quote.TextBox2 || quote.TextBox9) && (
                        <div className="review-row">
                          <span>
                            {translateString(locale, "If you have answered Yes to any of the questions above, please provide additional information")}
                          </span>
                          <span className="TextBox29">{quote.TextBox2 || quote.TextBox9}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* firm */}
                  <div className="review-firm">
                    <div className="review-section">
                      <h5>{translateString(locale, "Firm Info:")}</h5>
                      <div className="review-row">
                        <span>{translateString(locale, "Which firms are you contracted to do business:")}</span>
                        <span className="companyList">
                          {quote.companyList.length > 0 &&
                            quote.companyList.map((item, index) => {
                              return <span key={`companyList-${index}`}>{item}</span>;
                            })}
                        </span>
                      </div>
                      <div className="review-row">
                        <span>
                          {translateString(locale, "Which of these firms require their names to be shown on your Certificate of Insurance?")}
                        </span>
                        <span className="selectedCompanies">
                          {quote.selectedCompanies.length > 0 &&
                            quote.selectedCompanies.map((item, index) => {
                              return <span key={`selectedCompany-${index}`}>{item}</span>;
                            })}
                        </span>
                      </div>
                      <div className="review-row">
                        <span>
                          {translateString(
                            locale,
                            "Do you hold an ownership interest in one or more incorporated firms under which you will provide financial advisory services under our Life Insurance/Mutual Funds coverage?"
                          )}
                        </span>
                        <span>{translateString(locale, quote.legalName !== "" ? "Yes" : "No")}</span>
                      </div>
                      {quote.legalName && (
                        <>
                          <div className="review-row">
                            <span>{translateString(locale, "Legal Name of Incorporated Firm")}</span> <span>{quote.legalName}</span>
                          </div>
                          <div className="review-row">
                            <span>
                              {translateString(locale, "Is the corporation named above owned solely by you or jointly with your unlicensed spouse?")}
                            </span>
                            <span>{quote.corporationOwned}</span>
                          </div>
                          <div className="review-row">
                            <span>
                              {translateString(
                                locale,
                                "Do you employ any other licensed or unlicensed agents, or accept business from other licensed agents?"
                              )}
                            </span>
                            <span>{quote.employedAgents}</span>
                          </div>
                        </>
                      )}
                      <div className="review-row">
                        <span>{translateString(locale, "Address of Insured")}</span>
                        <span></span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Street Address")}</span>
                        <span>{quote.client_data.address}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "City")}</span>
                        <span>{quote.client_data.city}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Province")}</span>
                        <span>{quote.client_data.province}</span>
                      </div>
                      <div className="review-row">
                        <span>{translateString(locale, "Postal Code")}</span>
                        <span>{quote.client_data.postal}</span>
                      </div>
                    </div>
                  </div>
                  {/* licensing */}
                  <div className="review-licensing">
                    <div className="review-section">
                      <h5>{translateString(locale, "Licensing")}:</h5>
                      {quote.lifeInsuranceLicensingProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Life Insurance")}</span>
                          <span className="provinceList">
                            {quote.lifeInsuranceLicensingProvince.map((item, index) => {
                              return <span key={`lifeInsuranceLicensingProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.accidentSicknessProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Accident & Sickness")}</span>
                          <span className="provinceList">
                            {quote.accidentSicknessProvince.map((item, index) => {
                              return <span key={`accidentSicknessProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.mutualFundsProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Mutual Funds")}</span>
                          <span className="provinceList">
                            {quote.mutualFundsProvince.map((item, index) => {
                              return <span key={`mutualFundsProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.segregatedFundsProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Segregated Funds")}</span>
                          <span className="provinceList">
                            {quote.segregatedFundsProvince.map((item, index) => {
                              return <span key={`segregatedFundsProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.gicProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "GIC")}</span>
                          <span className="provinceList">
                            {quote.gicProvince.map((item, index) => {
                              return <span key={`gicProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.securitiesProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Securities")}</span>
                          <span className="provinceList">
                            {quote.securitiesProvince.map((item, index) => {
                              return <span key={`securitiesProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.incomeTaxProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Income Tax")}</span>
                          <span className="provinceList">
                            {quote.incomeTaxProvince.map((item, index) => {
                              return <span key={`incomeTaxProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.executorEstateProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Executor & Estate")}</span>
                          <span className="provinceList">
                            {quote.executorEstateProvince.map((item, index) => {
                              return <span key={`executorEstateProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.notaryPublicProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Notary Public")}</span>
                          <span className="provinceList">
                            {quote.notaryPublicProvince.map((item, index) => {
                              return <span key={`notaryPublicProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.commissionerOathsProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Commissioner of Oaths")}</span>
                          <span className="provinceList">
                            {quote.commissionerOathsProvince.map((item, index) => {
                              return <span key={`commissionerOathsProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.feeServicePlanningProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Fee for Service Planning")}</span>
                          <span className="provinceList">
                            {quote.feeServicePlanningProvince.map((item, index) => {
                              return <span key={`feeServicePlanningProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.divorceSpecialistProvince && (
                        <div className="review-row">
                          <span>{translateString(locale, "Divorce Specialist")}</span>
                          <span className="provinceList">
                            {quote.divorceSpecialistProvince.map((item, index) => {
                              return <span key={`divorceSpecialistProvince-${index}`}>{translateString(locale, item)}</span>;
                            })}
                          </span>
                        </div>
                      )}
                      {quote.other && quote.otherText && (
                        <div className="review-row">
                          <span>{translateString(locale, "Other")}</span>
                          <span>{quote.otherText}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* consent */}
                  <div className="review-consent">
                    <div className="review-section">
                      <h5>{translateString(locale, "Acknowledgement and Consent")}:</h5>
                      <div className="review-row">
                        <span>
                          {translateString(
                            locale,
                            "I agree that all personal information that I provide to the Broker is complete and accurate, and I consent to the collection, use, and disclosure of the personal information as set forth above."
                          )}
                        </span>
                        <span>{translateString(locale, "Yes")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <FormBottomNav
                disabled={false}
                isLoading={isLoading}
                prevText={translateString(locale, "Go Back")}
                prevPage={this.state.prevPage}
                className="Line Green top"
              />
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  fetchForms,
})(CheckoutReview);
